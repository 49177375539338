import { ProjectService } from './../../../service/project/project.service';
import { AuthServiceService } from './../../../service/auth/auth-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Socket } from 'ngx-socket-io';
import { SocketoneService } from 'src/app/service/socketconnection.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
declare var $ : any;

@Component({
  selector: 'app-activeprojectsdetail',
  templateUrl: './activeprojectsdetail.component.html',
  styleUrls: ['./activeprojectsdetail.component.scss']
})
export class ActiveprojectsdetailComponent implements OnInit,OnDestroy,AfterViewInit {

  public newmessage;
  public details;
  public question;
  public closedfaqs = [];
  public newFAQ = [];

  public fakeFaQ = []
  public loading = true;
  public show:boolean = false;
  public buttonName = 'question';
  public id;
  public messageArr;
  projectDetail;
  public hovershow: boolean;
  public indexNumber;
  page = 1;
  engineerDocs = false;
  files =[]
  urls = []
  docs = []
  bidReason;
  public fileListARR = []

  public clientURL;
  constructor(private loadingBar: LoadingBarService, private router: Router,  private sanitizer: DomSanitizer,private service: AuthServiceService,private projectService: ProjectService, private actRoute: ActivatedRoute, private socket: SocketoneService, private cookie: CookieService) { }
  ngAfterViewInit() {       
    this.socket.emit('projectMessage',{'projectID': this.id })
    this.socket.on('message',(data) => {
      console.log('message'+ data);
      
      this.messageArr = data;
      setTimeout(()=>{   

        var element = document.getElementById('bottomview');
        element.scrollIntoView({ block: "start", inline: "nearest"});  

      }, 1000);
    })
    this.socket.emit('readMessage', {
      'projectID': this.id,
      'owner': 'engineer'
    })
    this.socket.on('receiveMessage', data => {
     console.log( data)
     if(data.projectID == this.id){
       this.messageArr.push(data.message);
        setTimeout(()=>{   
          var element = document.getElementById('bottomview');
          element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});  
  
  
        }, 0);
        if(data.message.owner == 'client') {
          this.socket.emit('readMessage',{'projectID': this.id, 'owner':'engineer' })
        }
      }
     
   })
  }   
  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      // do your task for before route
      console.log('do your task for before route');
      
      return false;
    }
    this.service.avatarData.next(true)
    this.service.header.next(true)
    
    this.id = this.actRoute.snapshot.paramMap.get('id');
    console.log(this.id);
    
    this.loadingBar.start(0)
    
    this.files =[]
    this.urls = []
    this.docs = []
    this.fileListARR = []

    this.getData();
  }

  getData() {
    this.projectService.browseDetail(this.id).subscribe( (data: any) => {
      console.log(data)

      this.loadingBar.stop()

      if(typeof(data?.clientAvatar) != 'undefined'){
        let TYPED_ARRAY = new Uint8Array(data.clientAvatar.data);
        const STRING_CHAR = TYPED_ARRAY.reduce((data,byte) =>{
          return data + String.fromCharCode(byte);
        },'');
  
        let base64String = btoa(STRING_CHAR);
        this.clientURL =   this.sanitizer.bypassSecurityTrustUrl('data:image/jpg;base64, ' + base64String );
        console.log(this.clientURL);
      }
      for(let i = 0; i < data.project_docs.docs.length; i++) {
       
        if(data.project_docs.docs[i].docType == 'engineer'){
          this.engineerDocs = true;
        }

      }
      this. loading = false;
      this.details = data;
      this.newFAQ = data.faq;
     
    } , err =>       this.loadingBar.stop())
  }
  sendmessage() {
    // this.messageArr.push( {'type': 'receiver','message':this.newmessage});
    // this.newmessage = '';
    if (this.newmessage != '') {
      let objDate = Date.now(); 
      this.socket.emit('sendMessage',{'projectID':this.id, 'message': {'owner':"engineer",'message': this.newmessage, 'status': 'unread','time':objDate}});
      this.newmessage = '';  
    }


  }

  ngOnDestroy(){
    // this.messageArr = [];
    console.log('empty arr');
     this.socket.removeAllListeners('receiveMessage');
     this.socket.removeAllListeners('message');
  }

  mouseEnterevent(i) {
     console.log(i)
     this.hovershow = true;
     console.log(this.hovershow)
     this.indexNumber = i
  }

  mouseLeaveevent(i) {
    this.hovershow = false;
    console.log(this.hovershow);
   
  }

  async detectFiles(event) {

    let files = event.target.files;
    this.files = event.target.files;
    console.log(files);
    
    if (this.files) {
      for (let file of this.files) {
        const toBase64 = file => new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
      });
      // console.log(await toBase64(file).then( data => console.log(data)));
        await toBase64(file).then( data => {
          let newFile = {
            'FileValue': data,
            'FileType' : file.name.split('.').pop(),
            'Filename' : file.name
          }
          this.urls.push(newFile);
          console.log(this.urls)
        })

      }
    }
    Array.from(this.files).forEach((singleFile) => {
      console.log(singleFile);
      
      let filepush = {
        'fileType' : singleFile.type,
        'extension' : singleFile.name.split('.').pop(),
        'originalName' : singleFile.name
      }
        console.log(filepush)
        this.docs.push(filepush);
        this.fileListARR.push(singleFile)
    })
    console.log( this.docs);
    console.log(this.fileListARR)
    
  }

  completeRevision() {
    // $('#exampleModal').modal('show');
    this.loadingBar.start(0)
  
    console.log(this.docs); 
    let revisionData = {
      
      'fileDetails': this.docs
    }
    this.service.engineerSubmitDocs(revisionData, this.id).subscribe(  (response : any) => {
      // this.loadingBar.stop()

      console.log(response);
     
      
      for( let i = 0; i < response.length; i++) {
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': response[i].fileType}) };

        this.service.sendKey(response[i].url,  this.fileListARR[i], httpOptions).subscribe( data => {
          console.log( data ) 
          console.log(response[i])
          const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.cookie.get('token')})};
          
        const value = {
          'Key' : response[i].key,
          'extension': response[i].extension,
          'docType' : 'engineer',
          'url' : response[i].url,
          'originalName' :  response[i].originalName
          
      
        }
        console.log(value)
       
        

          this.service.engineerSubmitDocKey(value,this.id,httpOptions).subscribe( data => {
            console.log(data);
            
            this.projectService.browseDetail(this.id).subscribe( (data: any) => {
              this.loadingBar.stop()
              console.log(data)
              // this.getData();

              this. loading = false;
              this.details = data;
              this.newFAQ = data.faq;
              this.engineerDocs = true;
              // $('#exampleModal').modal('hide');
              this.docs = []
              this.fileListARR = []
              this.urls = []

            })
          } , err => this.loadingBar.stop());
        },
        err =>       this.loadingBar.stop() );

      }
    })
  }


  deleteDoc(index){
    console.log(index)
    this.docs.splice(index,1);
    this.fileListARR.splice(index,1)
    this.urls.splice(index,1)
    console.log(this.docs); 
    console.log(this.fileListARR);
    console.log(this.urls); 
  }

  getFullLocation(data1, data2, data3, data4) {

    return `${data1}, ${data2}, ${data3}, ${data4}`

  }

  submitProject() {
    this.loadingBar.start(0)
    this.projectService.projectsubmit(this.id).subscribe( data => {
      this.getData();
    }, err => {
      this.loadingBar.stop()
    })
  }

  getSubmitProBtn(data) {
    // console.log(data)
    if(this.engineerDocs == true && data == "false"){
      return true;
    }

  }
  getSubmitDocBtn(data) {
    console.log(data)
    if(this.engineerDocs == false || data == "false"){
      return true;
    }

  }
  
}
