<!-- <app-header></app-header> -->

<div class="main-container mb-5 pb-5">
    <ngx-loading-bar [includeSpinner] = false [color]="'#0066FF'"></ngx-loading-bar>

    <div class="container" *ngIf="loading == false; else Elseblock">
        <div class="heading-text">
            <p *ngIf="revisionDetail?.project_name"> {{revisionDetail.project_name | titlecase}}<span class="image"><img src="../../../assets/icons/chevron-right.svg" alt=""></span> <span>Project Details</span><span class="image"><img src="../../../assets/icons/chevron-right.svg" alt=""></span>
                <span style="color:  #0066FF;">Revision</span>
            </p>
        </div>
        <div class="revisionTitle">
            <div>
                <img src="../../../../assets/icons/backButton.svg" alt="" style="cursor: pointer;" routerLink="/dashboard/activeprojects/{{id}}"> <span>Revisions</span>
            </div>
        </div>
        <div class="card" *ngFor="let Project of revisionDetail?.revision | reverse; let i = index;">
            <div class="card-body">
                <div class="d-flex flex-wrap justify-content-between">
                    <div class="revisionNumber ">
                        #{{revisionDetail?.revision.length - i}}
                    </div>
                    <div >
                        <div class="buttonsGroup" *ngIf="Project.status == 'open'  ">

                            <span class="revisionbtn rebid" style="padding: 7px 20px" data-toggle="modal" data-target="#revisionmodal" *ngIf="Project.status == 'open' ">
                          Bid
                         </span>
                            <span class="revisionbtn rebid" style="padding: 7px 20px" data-toggle="modal" data-target="#revisionmodal" *ngIf="Project.status == 'rebid' ">
                            Rebid
                           </span>
                            <span class="revisionbtn accept" style="margin: 0;" data-toggle="modal" data-target="#acceptRevision">
                            Accept
                         </span>
                        </div>
                        <div class="buttonsGroup" *ngIf="Project.status == 'rebid'">

                            <span class="revisionbtn rebid" style="padding: 7px 20px" data-toggle="modal" data-target="#revisionmodal" *ngIf="Project.status == 'open' ">
                          Bid
                         </span>
                            <span class="revisionbtn rebid" style="padding: 7px 20px" data-toggle="modal" data-target="#revisionmodal" *ngIf="Project.status == 'rebid' ">
                            Rebid
                           </span>

                        </div>
                        <div class="buttonsGroup" *ngIf="Project.status == 'close'">
                            <span class="revisionbtn " style="margin: 0; color: #707070;background-color: #E2E0E0;cursor: auto;">
                            Closed By Client
                         </span>
                        </div>
                        <div class="buttonsGroup" *ngIf="Project.status == 'completed' ">

                            <span class="revisionbtn accept" style="margin: 0;color:#2DD6C4;background-color: #ccfffa;cursor: auto;">
                           Completed
                         </span>
                        </div>
                        <div class="buttonsGroup" *ngIf="Project.status == 'ongoing' ">

                            <span class="revisionbtn accept" style="margin: 0;color:white;background-color: #0066FF;" data-toggle="modal" data-target="#sendDocs">
                           Submit Revision
                         </span>
                        </div>

                        <div class="revisionbtn mb-2" *ngIf="Project.status == 'bid'" style="color: #F6566B;
                        margin-right: 10px;
                        text-align: right;font-weight: 600;">
                            Waiting for client's response on your bid
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-11">
                        <h5>Revision Detail</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12">
                        <p class="description">{{Project.revisonDetail}}</p>
                    </div>
                </div>
                <div class="row" *ngIf="Project?.bidReason">
                    <div class="col-xl-11">
                        <h5>Extra Bid Reason </h5>
                    </div>
                </div>
                <div class="row" *ngIf="Project?.bidReason">
                    <div class="col-xl-12">
                        <p class="description">{{Project.bidReason}}</p>
                    </div>
                </div>

                <div class="row"  *ngIf="Project?.docs.length != 0">
                    <div id="accordion" style="width: 100%;">
                        <div class="card" style="box-shadow: none;border: none;">
                            <div class="card-header ViewCard-header" style="background-color: transparent;border: none;" data-toggle="collapse" attr.data-target="#collapse_{{i}}">
                                View revision documents
                            </div>

                            <div id="collapse_{{i}}" class="collapse" data-parent="#accordion">
                                <div class="card-body">
                                    <div class="row" *ngIf= showClientDocLabel(Project?.docs)>
                                        <div class="col-xl-11">
                                            <h5>Files uploaded by client</h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div style="display: flex;">
                                                <div class="uploads">


                                                    <div *ngFor="let url of Project.docs">

                                                        <ng-container *ngIf="url.docType ==='client' ">
                                                            <a href="{{url.Key}}" download class="preview thumbnail " *ngIf="url.extension==='png' ">
                                                                <img src="{{url.Key}} " style="width: 95%;height: 95%; ">
                                                                <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>
        
        
                                                            </a>
                                                        </ng-container>
        
                                                        <ng-container *ngIf="url.docType ==='client' ">
                                                            <a href="{{url.Key}}" download class="preview thumbnail " *ngIf="url.extension==='jpg' ">
                                                                <img src="{{url.Key}} " style="width: 95%;height: 95%; ">
                                                                <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>
        
        
                                                            </a>
                                                        </ng-container>
                                                        <ng-container *ngIf="url.docType ==='client' ">
                                                            <a href="{{url.Key}}" download class="preview thumbnail " *ngIf="url.extension==='jpeg' ">
                                                                <img src="{{url.Key}} " style="width: 95%;height: 95%; ">
                                                                <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>
        
        
                                                            </a>
                                                        </ng-container>
                                                        <ng-container *ngIf="url.docType ==='client' ">
        
                                                            <a href="{{url.Key}}" download class="preview thumbnail pdfthumbnail " *ngIf="url.extension==='pdf' ">
                                                                <!-- <pdf-viewer src="{{url.Key}} " [show-all]="false " [zoom]="0.12 " [render-text]="true " [page]="page " style="display: block; "></pdf-viewer> -->
                                                                <img src="../../../../assets/icons/pdf.png ">
                                                                <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>
        
        
                                                            </a>
                                                        </ng-container>
        
                                                        <ng-container *ngIf="url.docType ==='client' ">
        
                                                            <a href="{{url.Key}}" download class="preview thumbnail " *ngIf="url.extension==='dwg' ">
                                                                <img src="../../../../assets/icons/dwg.png ">
                                                                <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>
        
        
                                                            </a>
                                                        </ng-container>
                                                        <ng-container *ngIf="url.docType ==='client' ">
                                                            <a href="{{url.Key}}" download class="preview thumbnail " *ngIf="url.extension==='docx' ">
                                                                <img src="../../../../assets/icons/doc.png ">
                                                                <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>
        
        
                                                            </a>
                                                        </ng-container>
        
                                                        <ng-container *ngIf="url.docType ==='client' ">
                                                            <a href="{{url.Key}}" download class="preview thumbnail " *ngIf="url.extension==='doc' ">
                                                                <img src="../../../../assets/icons/doc.png ">
                                                                <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>
        
                                                            </a>
                                                        </ng-container>
                                                        <ng-container *ngIf="url.docType ==='client' ">
                                                            <a href="{{url.Key}}" download class="preview thumbnail " *ngIf="url.extension==='zip' || url.extension==='rar' ">
                                                                <img src="../../../../assets/icons/zip.png ">
                                                                <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>
        
                                                            </a>
                                                        </ng-container>
                                                        <ng-container *ngIf="url.docType ==='client' ">
                                                            <a href="{{url.Key}}" download class="preview thumbnail " *ngIf="url.extension==='xls'  ">
                                                                <img src="../../../../assets/icons/xls.png ">
                                                                <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>
        
                                                            </a>
                                                        </ng-container>
                                                        <ng-template >
                                                         
                                                        </ng-template>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf= showEngineerDocLabel(Project?.docs)>
                                        <div class="col-xl-11">
                                            <h5>Files uploaded by me</h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 ">
                                            <div style="display: flex;">
                                                <div class="uploads">

                                                    <div *ngFor="let url of Project.docs">

                                                        <ng-container *ngIf="url.docType ==='engineer' ">
                                                            <a href="{{url.Key}}" download class="preview thumbnail " *ngIf="url.extension==='png' ">
                                                                <img src="{{url.Key}} " style="width: 95%;height: 95%; ">
                                                                <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>
        
        
                                                            </a>
                                                        </ng-container>
        
                                                        <ng-container *ngIf="url.docType ==='engineer' ">
                                                            <a href="{{url.Key}}" download class="preview thumbnail " *ngIf="url.extension==='jpg' ">
                                                                <img src="{{url.Key}} " style="width: 95%;height: 95%; ">
                                                                <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>
        
        
                                                            </a>
                                                        </ng-container>
                                                        <ng-container *ngIf="url.docType ==='engineer' ">
                                                            <a href="{{url.Key}}" download class="preview thumbnail " *ngIf="url.extension==='jpeg' ">
                                                                <img src="{{url.Key}} " style="width: 95%;height: 95%; ">
                                                                <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>
        
        
                                                            </a>
                                                        </ng-container>
                                                        <ng-container *ngIf="url.docType ==='engineer' ">
        
                                                            <a href="{{url.Key}}" download class="preview thumbnail pdfthumbnail " *ngIf="url.extension==='pdf' ">
                                                                <!-- <pdf-viewer src="{{url.Key}} " [show-all]="false " [zoom]="0.12 " [render-text]="true " [page]="page " style="display: block; "></pdf-viewer> -->
                                                                <img src="../../../../assets/icons/pdf.png ">
                                                                <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>
        
        
                                                            </a>
                                                        </ng-container>
        
                                                        <ng-container *ngIf="url.docType ==='engineer' ">
        
                                                            <a href="{{url.Key}}" download class="preview thumbnail " *ngIf="url.extension==='dwg' ">
                                                                <img src="../../../../assets/icons/dwg.png ">
                                                                <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>
        
        
                                                            </a>
                                                        </ng-container>
                                                        <ng-container *ngIf="url.docType ==='engineer' ">
                                                            <a href="{{url.Key}}" download class="preview thumbnail " *ngIf="url.extension==='docx' ">
                                                                <img src="../../../../assets/icons/doc.png ">
                                                                <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>
        
        
                                                            </a>
                                                        </ng-container>
        
                                                        <ng-container *ngIf="url.docType ==='engineer' ">
                                                            <a href="{{url.Key}}" download class="preview thumbnail " *ngIf="url.extension==='doc' ">
                                                                <img src="../../../../assets/icons/doc.png ">
                                                                <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>
        
                                                            </a>
                                                        </ng-container>
                                                        <ng-container *ngIf="url.docType ==='engineer' ">
                                                            <a href="{{url.Key}}" download class="preview thumbnail " *ngIf="url.extension==='zip' || url.extension==='rar' ">
                                                                <img src="../../../../assets/icons/zip.png ">
                                                                <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>
        
                                                            </a>
                                                        </ng-container>
                                                        <ng-container *ngIf="url.docType ==='engineer' ">
                                                            <a href="{{url.Key}}" download class="preview thumbnail " *ngIf="url.extension==='xls' ">
                                                                <img src="../../../../assets/icons/xls.png ">
                                                                <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>
        
                                                            </a>
                                                        </ng-container>
                                                       
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>



                <div class="row">

                    <div class="col-12 col-md-4 details">

                        <div>
                            <i-feather name="calendar" class="big"></i-feather>
                        </div>
                        <div>
                            <p class="title">Received on</p>
                            <p class="value">{{Project.date | date:'short' : '-0800'}}</p>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 mt-3  details">
                        <div *ngIf=" Project.revisionBidAmount ">
                            <i-feather name="dollar-sign" class="big"></i-feather>
                        </div>
                        <div *ngIf=" Project.revisionBidAmount ">
                            <p class="title">Extra charges</p>
                            <p class="value" style="color: #F6566B;">{{Project.revisionBidAmount }}</p>
                        </div>



                    </div>

                    <div class=" col-12 col-md-4 mt-3 details justify-content-center justify-content-lg-end" >
                        <div>
                            <img src="../../../../assets/icons/message.svg" alt="">
                        </div>
                        <div>
                            <p class="value" style="color: #0066FF;cursor:pointer" routerLink="/dashboard/activeprojects/{{id}}">Chat now</p>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="revisionmodal" tabindex="-1" role="dialog">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <p class="modal-title" id="sendDocsModel">Bid on project revision</p>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
    <span aria-hidden="true"><img src="../../../../assets/icons/cross.svg" alt="" style="width:15px"></span>
    </button>
                            </div>
                            <div class="modal-body">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">Bid Value: $</span>
                                    </div>
                                    <input type="number" class="form-control" [(ngModel)]="bidAmount" (keyup)="plaformfee()">
                                </div>
                                <!-- <p *ngIf="bidAmount != undefined" style="    margin-left: 12px;">Platform fee: ${{platformFee}}</p>
                                <p *ngIf="bidAmount != undefined" style="font-weight: 700;    margin-left: 12px;">Total bid: ${{commision}}</p> -->

                                <div class="form-group">
                                    <label for="CHAGESTEXT">Reason for extra charges</label>
                                    <textarea class="form-control" id="CHAGESTEXT" rows="3" [(ngModel)]="bidReason"></textarea>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <span data-dismiss="modal" (click)=sendBid() *ngIf="popupBtn.status == 'open'">SUBMIT</span>
                                <span data-dismiss="modal" (click)=sendReBid() *ngIf="popupBtn.status == 'rebid'">SUBMIT</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
    <ng-template #Elseblock>
        <div style="display: flex;align-items: center;justify-content: center;height: 70vh;">
            <app-loading></app-loading>
        </div>
    </ng-template>
</div>



<!-- mode for sending docs -->

<div class="modal fade" id="sendDocs" tabindex="-1" role="dialog"  data-backdrop="static" aria-labelledby="sendDocsModel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <p class="modal-title" id="sendDocsModel">Submit revision documents</p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true"><img src="../../../../assets/icons/cross.svg" alt="" style="width:15px"></span>
</button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label for="exampleInputEmail1 proHireSubHeading" style="font-weight: 700;">Upload Documents</label><span style="color: #F6566B;">*</span>
                    <div style="display: flex;">
                        <div class="uploads">
                            <div class="select preview">
                                <label for="test" class="filelabel">
                                    <div class="click">+</div>
                                    <input type="file" id="test" multiple (change)="detectFiles($event)" accept="image/jpeg,image/png,application/pdf,.doc,.docx,.dwg,.zip,.rar,.xls">
                                  </label>
                            </div>

                            <div *ngFor="let url of urls; let i = index">
                                <div class="preview thumbnail" *ngIf="url.FileType === 'png' || url.FileType === 'jpg' || url.FileType === 'jpeg'" style="position: relative;">
                                    <i class="icon-close" (click) = "deleteDoc(i)"><img src="../../../../assets/icons/close.svg" alt=""></i>

                                    <img [src]="url.FileValue" style="width: 95%;    height: 95%;
                                    ">
                                    <p>{{url.Filename}}</p>

                                </div>

                                <div class="preview thumbnail pdfthumbnail" *ngIf="url.FileType === 'pdf'" style="position: relative;">
                                    <i class="icon-close" (click) = "deleteDoc(i)"><img src="../../../../assets/icons/close.svg" alt=""></i>

                                    <!-- <pdf-viewer src="{{url.FileValue}}" [show-all]="false" [zoom]="0.12" [render-text]="true" [page]="page" style="display: block;"></pdf-viewer> -->
                                    <img src="../../../../assets/icons/pdf.png">
                                    <p>{{url.Filename}}</p>
                                </div>
                                <div class="preview thumbnail" *ngIf="url.FileType === 'doc' || url.FileType === 'docx'  " style="position: relative;">
                                    <i class="icon-close" (click) = "deleteDoc(i)"><img src="../../../../assets/icons/close.svg" alt=""></i>
                                    <img src="../../../../assets/icons/doc.png">
                                    <p>{{url.Filename}}</p>
                                </div>
                                <div class="preview thumbnail" *ngIf="url.FileType === 'dwg' " style="position: relative;">
                                    <i class="icon-close" (click) = "deleteDoc(i)"><img src="../../../../assets/icons/close.svg" alt=""></i>
                                    <img src="../../../../assets/icons/dwg.png">
                                    <p>{{url.Filename}}</p>
                                </div>
                                <div class="preview thumbnail" *ngIf="url.FileType === 'rar' || url.FileType === 'zip'  " style="position: relative;">
                                    <i class="icon-close" (click) = "deleteDoc(i)"><img src="../../../../assets/icons/close.svg" alt=""></i>
                                    <img src="../../../../assets/icons/zip.png">
                                    <p>{{url.Filename}}</p>
                                </div>
                                <div class="preview thumbnail" *ngIf="url.FileType === 'xls' " style="position: relative;">
                                    <i class="icon-close" (click) = "deleteDoc(i)"><img src="../../../../assets/icons/close.svg" alt=""></i>
                                    <img src="../../../../assets/icons/xls.png">
                                    <p>{{url.Filename}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="form-group">
                    <label for="CHAGESTEXT"></label>
                    <textarea class="form-control" id="CHAGESTEXT" rows="3" [(ngModel)]="bidReason"></textarea>
                </div> -->
            </div>
            <div class="modal-footer">
                <!-- <span data-dismiss="modal" (click)=completeRevision()>SUBMIT</span> -->
                <button class="btn" style="    background: #15bd95;
                color: white;
                font-size: 14px;
                font-weight: 700;
                padding: 8px 24px;
                border-radius: 5px;" data-dismiss="modal" (click)=completeRevision() [disabled]="urls.length == 0">SUBMIT</button>
            </div>
        </div>
    </div>
</div>

<!-- modal for docs -->

<!-- <div class="modal hide " data-keyboard="false" data-backdrop="static" tabindex="-1" role="dialog" id="exampleModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content loadingmodal">

            <div class="modal-body">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <span class="loadText">Loading please wait...</span>
            </div>

        </div>
    </div>
</div> -->

<!-- model complete Project -->
<div class="modal fade" id="acceptRevision" tabindex="-1" role="dialog"  aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog  completeConfirm" role="document">
        <div class="modal-content">

            <div class="modal-body" style="padding:25px">
                <div class="approve" style="text-align: center;">
                    <!-- <img src="../../../../../../assets/newimages/reject.svg" alt=""> -->
                    <div>
                        <p style="font-weight:600; font-size: 16px;"> Are you sure you want to Accept a revision?</p>
                    </div>
                </div>
                <div class="footer" style="margin-top: 10px;">

                    <div class="buttons">
                        <div class="cancel-btn" data-dismiss="modal">No</div>
                        <div class="approve-btn" data-dismiss="modal" (click)="acceptBid()" >Yes</div>
                        
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>