import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResendemailGuard implements CanActivate {
  constructor(private cookie: CookieService, private router: Router ){}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.cookie.check('email')) {
      return true
    }
    this.router.navigate(['/onboard/signup'])
    return false;
  }
  
}
