<header *ngIf="isHeader">
    <nav   class="navbar navbar-expand-lg ">
        <a class="navbar-brand mr-0 mr-md-5" routerLink="/dashboard/browseprojects" style="color:black">
            <!-- <img src="../../../assets/icons/ProHire.png" alt=""> -->
            <b>Illumine I</b> | Engineer
        </a>
        <div class="mobile-notification d-flex align-items-center ">
            <div>
				<a class="nav-link notification px-4 dropdown-toggle" (click)="removeBtn()" id="notifidown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="../../../assets/icons/bellempty.svg" alt="" > <span *ngIf="isNotification" class="badge badge-light" >{{notifyCounter()}}</span> </a>
                    <div class="dropdown-menu notify" aria-labelledby="notifidown"  id="style-3">
                        <div class="notify-container">
                            <p class="notifications" (click)="$event.stopPropagation()">Notifications <span (click) ="clearNotification()" style="float: right;
                                font-size: 13px;
                                margin-top: 6px;
                                color: #f6566b;">
                                Mark all as read
                            </span></p>
                            <!-- <div class="row"  (click)="$event.stopPropagation()">
                                <div class="col-8">
                                    
                                </div>
                                <div class="col-4">
                                </div>
                            </div> -->
                            <ul class="nav nav-pills " id="pills-tab" role="tablist" style="border-bottom: 1px solid #dee2e6;">
                                <li class="nav-item" (click)="$event.stopPropagation()">
                                  <a (click)="test($event)" class="nav-link active" id="pills-notification-tab" data-toggle="pill" href="#pills-notification1" role="tab" aria-controls="pills-notification" aria-selected="true">Project</a>
                                </li>
                                <li class="nav-item" (click)="$event.stopPropagation()">
                                  <a (click)="test($event)" class="nav-link" id="pills-message-tab" data-toggle="pill" href="#pills-message1" role="tab" aria-controls="pills-message" aria-selected="false">Message</a>
                                </li>
                               
                              </ul>
                              <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade show active" id="pills-notification1" role="tabpanel" aria-labelledby="pills-notification-tab">
                                    <ng-container *ngIf = "projectNotifyData.length != 0; else NoProjectNotify">
                                        <div *ngFor="let notifydata of projectNotifyData ; let i = index">
                                            <div  class="item-box " *ngIf="notifydata.projectID?.status == 'open'"   routerLink="/dashboard/browseprojects/{{notifydata?.projectID?._id}}" (click)="goto(notifydata?._id,i)" [ngStyle]="{'background-color': notifydata.status == 'unread' ? 'rgb(247 246 246)' : 'transparent'}">
                                                <!-- <div class="items image">
                                                    <img src="../../../assets/icons/profile.svg" alt="">
                
                                                </div> -->
                                                <div class="items textvalue">
                                                    <p style="font-size:16px"><b>{{notifydata.projectID?.projectName | titlecase}}</b></p>
                                                    <p style="font-size:14px"> {{notifydata.content}}</p>
                                                    <p style="    margin-top: 9px;
                                                    color: #0066ff;
                                                    font-weight: 600;">{{notifydata.createdAt | date:'short' : '-0800'}}</p>
                                                </div>
                                            </div>
                                         
                                            <div  class="item-box " *ngIf="notifydata.projectID?.status == 'ongoing'"   routerLink="/dashboard/activeprojects/{{notifydata?.projectID?._id}}" (click)="goto(notifydata?._id,i)" [ngStyle]="{'background-color': notifydata.status == 'unread' ? 'rgb(247 246 246)' : 'transparent'}">
                                                <div class="items textvalue">
                                                    <p style="font-size:16px"><b>{{notifydata.projectID.projectName | titlecase}}</b></p>
                                                    <p style="font-size:14px"> {{notifydata.content}}</p>
                                                    <p style="    margin-top: 9px;
                                                    color: #0066ff;
                                                    font-weight: 600;">{{notifydata.createdAt | date:'short' : '-0800'}}</p>

                                                </div>
                                            </div>
                                            <div  class="item-box " *ngIf="notifydata.projectID?.status == 'completed'"   routerLink="/dashboard/completedprojects/{{notifydata?.projectID?._id}}" (click)="goto(notifydata?._id, i)" [ngStyle]="{'background-color': notifydata.status == 'unread' ? 'rgb(247 246 246)' : 'transparent'}">
                                                <!-- <div class="items image">
                                                    <img src="../../../assets/icons/profile.svg" alt="">
                                                </div> -->
                                                <div class="items textvalue">
                                                    <p style="font-size:16px"><b>{{notifydata.projectID?.projectName | titlecase}}</b></p>
                                                    <p style="font-size:14px"> {{notifydata.content}}</p>
                                                    <p style="    margin-top: 9px;
                                                    color: #0066ff;
                                                    font-weight: 600; ">{{notifydata.createdAt | date:'short' : '-0800'}}</p>

                                                </div>
                                            </div>
                                            <div  class="item-box " *ngIf="notifydata.projectID == null"   (click)="goto(notifydata?._id, i)" [ngStyle]="{'background-color': notifydata.status == 'unread' ? 'rgb(247 246 246)' : 'transparent'}">
                                                <!-- <div class="items image">
                                                    <img src="../../../assets/icons/profile.svg" alt="">
                                                </div> -->
                                                <div class="items textvalue">
                                                    <p style="font-size:16px"><b>{{notifydata.projectID?.projectName | titlecase}}</b></p>
                                                    <p style="font-size:14px"> {{notifydata.content}}</p>
                                                    <p style="    margin-top: 9px;
                                                    color: #0066ff;
                                                    font-weight: 600;">{{notifydata.createdAt | date:'short' : '-0800'}}</p>

                                                </div>
                                            </div>
                                         
                                        </div>
                                    </ng-container>
                                    <ng-template #NoProjectNotify>
                                        <!-- <p  style="padding:35px">No project notification</p> -->
                                        <div style="padding:58px">
                                            <app-notificationempty [message]="'No project notification'"></app-notificationempty>
                                        </div>
                                     </ng-template>
                                </div>
                                <div class="tab-pane fade" id="pills-message1" role="tabpanel" aria-labelledby="pills-message-tab">
                                    <ng-container *ngIf = "notificationdata.length != 0; else messgaeNotify ">
                                        
                                        <div *ngFor="let notifydata of notificationdata; let i = index ">
                                            <div  class="item-box "  routerLink="/dashboard/activeprojects/{{notifydata?.projectID}}" (click)="goto(notifydata?.projectID, i)" style="background: rgb(247 246 246) ">
                                                <!-- <div class="items image">
                                                    <img src="../../../assets/icons/profile.svg" alt="">
                
                                                </div> -->
                                                <div class="items textvalue">
                                                    <p style="font-size:16px"><b>{{notifydata?.projectName | titlecase}}</b> has a new message</p>
                                                    <p style="    margin-top: 9px;
                                                    color: #0066ff;
                                                    font-weight: 600;">{{notifydata?.message.time | date:'short' : '-0800'}}</p>
                                                </div>
                                            </div>
                                         
                                        </div>
                                        
                                    </ng-container>
                                    <ng-template #messgaeNotify>
                                        <!-- <p style="padding:35px">No message notificaation</p> -->
                                        <div style="padding:58px">
                                            <app-notificationempty [message]="'No message notification'" ></app-notificationempty>
                                        </div>
                                     </ng-template>
                                </div>
                              </div>
                          
                                                   

                        </div>
                    </div>
			</div>
			<div>
				<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" > <img src="../../../assets/icons/menu.svg" alt=""> </button>
			</div>
		</div>

        <div class="collapse navbar-collapse " id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto links">
                <li class="nav-item mr-4 borderitem">
                    <a class="nav-link navbartext" routerLinkActive="actived" routerLink="/dashboard/browseprojects">Browse Projects <span class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item mr-4 borderitem">
                    <a class="nav-link navbartext" routerLinkActive="actived" routerLink="/dashboard/activeprojects">Active Projects</a>
                </li>
                <li class="nav-item mr-4 borderitem">
                    <a class="nav-link navbartext" routerLinkActive="actived" routerLink="/dashboard/activebids">Bids</a>
                </li>
                <li class="nav-item mr-4 borderitem">
                    <a class="nav-link navbartext" routerLinkActive="actived" routerLink="/dashboard/completedprojects">Completed Projects</a>
                </li>

            </ul>
            <div class="navbartext mt-1 extraLinks  " >
				<a class="profileColor" routerLink="/onboard/profile" routerLinkActive="actived" >Profile</a>
			</div>
			<div class="navbartext extraLinks" (click)="logout()">
				Logout
			</div>
            <ul class="navbar-nav my-2 my-lg-0">
                <li class="nav-item mr-3 dropdown avatarrrr ">
                    <div>
                        <a class="nav-link notification px-4 dropdown-toggle" (click)="removeBtn()" id="notifidown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="../../../assets/icons/bellempty.svg" alt="" > <span *ngIf="isNotification" class="badge badge-light" >{{notifyCounter()}}</span> </a>
                            <div class="dropdown-menu notify" aria-labelledby="notifidown"  id="style-3">
                                <div class="notify-container">
                                    <p class="notifications" (click)="$event.stopPropagation()">Notifications <span (click) ="clearNotification()" style="float: right;
                                        font-size: 13px;
                                        margin-top: 6px;
                                        color: #f6566b;">
                                         Mark all as read

                                    </span></p>
                                    <!-- <div class="row"  (click)="$event.stopPropagation()">
                                        <div class="col-8">
                                            
                                        </div>
                                        <div class="col-4">
                                        </div>
                                    </div> -->
                                    <ul class="nav nav-pills " id="pills-tab" role="tablist" style="border-bottom: 1px solid #dee2e6;">
                                        <li class="nav-item" (click)="$event.stopPropagation()">
                                          <a (click)="test($event)" class="nav-link active" id="pills-notification-tab" data-toggle="pill" href="#pills-notification" role="tab" aria-controls="pills-notification" aria-selected="true">Project</a>
                                        </li>
                                        <li class="nav-item" (click)="$event.stopPropagation()">
                                          <a (click)="test($event)" class="nav-link" id="pills-message-tab" data-toggle="pill" href="#pills-message" role="tab" aria-controls="pills-message" aria-selected="false">Message</a>
                                        </li>
                                       
                                      </ul>
                                      <div class="tab-content" id="pills-tabContent">
                                        <div class="tab-pane fade show active" id="pills-notification" role="tabpanel" aria-labelledby="pills-notification-tab">
                                            <ng-container *ngIf = "projectNotifyData.length != 0; else NoProjectNotify">
                                                <div *ngFor="let notifydata of projectNotifyData ; let i = index">
                                                    <div  class="item-box " *ngIf="notifydata.projectID?.status == 'open'"   routerLink="/dashboard/browseprojects/{{notifydata?.projectID?._id}}" (click)="goto(notifydata?._id,i)" [ngStyle]="{'background-color': notifydata.status == 'unread' ? 'rgb(247 246 246)' : 'transparent'}">
                                                        <!-- <div class="items image">
                                                            <img src="../../../assets/icons/profile.svg" alt="">
                        
                                                        </div> -->
                                                        <div class="items textvalue">
                                                            <p style="font-size:16px"><b>{{notifydata.projectID?.projectName | titlecase}}</b></p>
                                                            <p style="font-size:14px"> {{notifydata.content}}</p>
                                                            <p style="    margin-top: 9px;
                                                            color: #0066ff;
                                                            font-weight: 600;">{{notifydata.createdAt | date:'short' : '-0800'}}</p>
                                                        </div>
                                                    </div>
                                                 
                                                    <div  class="item-box " *ngIf="notifydata.projectID?.status == 'ongoing'"   routerLink="/dashboard/activeprojects/{{notifydata?.projectID?._id}}" (click)="goto(notifydata?._id,i)" [ngStyle]="{'background-color': notifydata.status == 'unread' ? 'rgb(247 246 246)' : 'transparent'}">
                                                        <div class="items textvalue">
                                                            <p style="font-size:16px"><b>{{notifydata.projectID.projectName | titlecase}}</b></p>
                                                            <p style="font-size:14px"> {{notifydata.content}}</p>
                                                            <p style="    margin-top: 9px;
                                                            color: #0066ff;
                                                            font-weight: 600;">{{notifydata.createdAt | date:'short' : '-0800'}}</p>
        
                                                        </div>
                                                    </div>
                                                    <div  class="item-box " *ngIf="notifydata.projectID?.status == 'completed'"   routerLink="/dashboard/completedprojects/{{notifydata?.projectID?._id}}" (click)="goto(notifydata?._id, i)" [ngStyle]="{'background-color': notifydata.status == 'unread' ? 'rgb(247 246 246)' : 'transparent'}">
                                                        <!-- <div class="items image">
                                                            <img src="../../../assets/icons/profile.svg" alt="">
                                                        </div> -->
                                                        <div class="items textvalue">
                                                            <p style="font-size:16px"><b>{{notifydata.projectID?.projectName | titlecase}}</b></p>
                                                            <p style="font-size:14px"> {{notifydata.content}}</p>
                                                            <p style="    margin-top: 9px;
                                                            color: #0066ff;
                                                            font-weight: 600; ">{{notifydata.createdAt | date:'short' : '-0800'}}</p>
        
                                                        </div>
                                                    </div>
                                                    <div  class="item-box " *ngIf="notifydata.projectID == null"   (click)="goto(notifydata?._id, i)" [ngStyle]="{'background-color': notifydata.status == 'unread' ? 'rgb(247 246 246)' : 'transparent'}">
                                                        <!-- <div class="items image">
                                                            <img src="../../../assets/icons/profile.svg" alt="">
                                                        </div> -->
                                                        <div class="items textvalue">
                                                            <p style="font-size:16px"><b>{{notifydata.projectID?.projectName | titlecase}}</b></p>
                                                            <p style="font-size:14px"> {{notifydata.content}}</p>
                                                            <p style="    margin-top: 9px;
                                                            color: #0066ff;
                                                            font-weight: 600;">{{notifydata.createdAt | date:'short' : '-0800'}}</p>
        
                                                        </div>
                                                    </div>
                                                 
                                                </div>
                                            </ng-container>
                                            <ng-template #NoProjectNotify>
                                                <!-- <p  style="padding:35px">No project notification</p> -->
                                                <div style="padding:58px">
                                                    <app-notificationempty [message]="'No project notification'"></app-notificationempty>
                                                </div>
                                             </ng-template>
                                        </div>
                                        <div class="tab-pane fade" id="pills-message" role="tabpanel" aria-labelledby="pills-message-tab">
                                            <ng-container *ngIf = "notificationdata.length != 0; else messgaeNotify ">
                                                
                                                <div *ngFor="let notifydata of notificationdata; let i = index ">
                                                    <div  class="item-box "  routerLink="/dashboard/activeprojects/{{notifydata?.projectID}}" (click)="goto(notifydata?.projectID, i)" style="background: rgb(247 246 246) ">
                                                        <!-- <div class="items image">
                                                            <img src="../../../assets/icons/profile.svg" alt="">
                        
                                                        </div> -->
                                                        <div class="items textvalue">
                                                            <p style="font-size:16px"><b>{{notifydata?.projectName | titlecase}}</b> has a new message</p>
                                                            <p style="    margin-top: 9px;
                                                            color: #0066ff;
                                                            font-weight: 600;">{{notifydata?.message.time | date:'short' : '-0800'}}</p>
                                                        </div>
                                                    </div>
                                                 
                                                </div>
                                                
                                            </ng-container>
                                            <ng-template #messgaeNotify>
                                                <!-- <p style="padding:35px">No message notificaation</p> -->
                                                <div style="padding:58px">
                                                    <app-notificationempty [message]="'No message notification'" ></app-notificationempty>
                                                </div>
                                             </ng-template>
                                        </div>
                                      </div>
                                  
                                                           
        
                                </div>
                            </div>
                    </div>
                </li>
                <li class="nav-item dropdown profilepic py-3 py-lg-0 avatarrrr">
                    <a class="nav-link dropdown-toggle" id="dropdown07" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img *ngIf="!imageurl" class="avatarimage" src="../../../../assets/other/user.svg" alt="">
                        <img *ngIf="imageurl"  class="avatarimage" [src]="imageurl" alt="">
                        <span *ngIf="username" class="downarrow">{{username | titlecase}}</span>  <span *ngIf="!username" class="downarrow">Username</span> <span><img src="../../../assets/icons/downarrow.svg" alt=""></span></a>
                    <div class="dropdown-menu" aria-labelledby="dropdown07">
                        <a class="dropdown-item" routerLink="/onboard/profile">Profile</a>
                        <a class="dropdown-item" (click)="logout()">Logout</a>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
    <!-- <nav  [ngStyle]="{'background-color': color? 'white' : 'transparent'}" *ngIf="navigationLinks == false" class="navbar navbar-expand-md navbar-dark fixed-top fakenav" style="box-shadow: none!important;">
        <a class="navbar-brand mr-3 brand" routerLink="/myprojetcs" style="color:black">
        
            <b>Illumine I</b> | Engineer
        </a>
    </nav> -->
</header>


<!--block Modal -->
<div class="modal fade" id="blockModal" tabindex="-1" role="dialog"  data-keyboard="false" data-backdrop="static"  aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Account access blocked</h5>
  
        </div>
        <div class="modal-body">
          <h5>Kindly contact Admin</h5>
          <p>kavya.solar005@gmail.com</p>
          <button type="button" class="btn btn-primary btn-lg btn-block " (click)="logout()">Logout</button>
        </div>
  
      </div>
    </div>
  </div>
  

