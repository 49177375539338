
<!-- <app-header></app-header> -->

<div class="main-container">
    <ngx-loading-bar [includeSpinner] = false [color]="'#0066FF'"></ngx-loading-bar>

    <div class="container" *ngIf="loading == false; else elseBlock">
        <div class="heading-text">
            <p>{{details.project.projectName | titlecase}}<span class="image"><img src="../../../assets/icons/chevron-right.svg" alt=""></span> <span style="color:  #0066FF;">Project Details</span></p>
        </div>
        <div class="row content-box mb-5 pb-5">
            <div class="col-xl-8 col-lg-8 content-box-col">
                <div class="card card-container" id="style-3">
                    <div class="card-body">
                        <div class="responsive">
                            <div class="d-flex justify-content-between statusandbid">
                                <div >
                                    <p class="openbid">Open</p>
                                </div>

                            </div>
                            <h5>
                                {{details.project.projectName | titlecase}}
                            </h5>

                            <div class="d-flex">
                                <div class=" pro">
                                    <img *ngIf="!details?.clientAvatar" class="engineerprofile" src="../../../../assets/other/user.svg" alt="">
                                    <img  *ngIf="details?.clientAvatar" class="engineerprofile" [src]="clientURL" alt="">
                                </div>
                                <div class="  ml-2">
                                    <p class="name">{{details.clientName | titlecase}}</p>
                                    <i-feather name="map-pin" style="width: 16px;
                                    height: 16px;"></i-feather>
                                     <span class="place">{{ getFullLocation(details.project.addressLine1, details.project.addressLine2, details.project.city, details.project.state) | titlecase}}, USA</span>
                                </div>
                            </div>
                            <p class="condition">Requirements</p>
                            <!-- <div class="row projectdetials">
                                <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <div class="row">
                                        <div class="col-2 col-sm-1  col-xl-2">
                                            <img class="licenseicon" src="../../../../assets/icons/education (1).svg" alt="">
                                        </div>
                                        <div class=" col-10  col-xl-10">
                                            <p class="labels">License Type</p>
                                            <p class="values">{{details.project.licenseType | titlecase}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <div class="row">
                                        <div class="col-2 col-sm-1  col-xl-2">
                                            <img class="clockicon" src="../../../../assets/icons/clock.svg" alt="">
                                        </div>
                                        <div class="col-10 col-xl-10">
                                            <p class="labels">Project Duration</p>
                                            <p class="values">{{details.project.duration}} Days</p>
                                        </div>
                                    </div>
                                </div>
                           
                            </div> -->
                            <div class="row projectdetials">
                                <div class="col-xl-12" style="display: flex;" > 
                                    <div class="details" >
                                        <div>
                                            <!-- <img src="../../../../assets/icons/education (1).svg" alt=""> -->
                                            <i-feather name="briefcase" class="big "></i-feather>

                                        </div>
                                        <div>
                                            <p class="title">License Type</p>
                                            <p class="value">{{details?.project.licenseType | titlecase}}</p>
                                        </div>
                                    </div>
                                  
                          
                                   
                                </div>
                                
                            </div>
                            <p class="condition">Project Description</p>
                            <p class="description capital">
                                {{details.project.description}}
                            </p>
                            <p class="condition">Project Documents</p>
                            <div class="row ">
                                <div class="col-12 ">
                                    <div style="display: flex; ">
                                        <div class="uploads ">

                                            <div *ngFor="let url of details?.project_docs.docs " style="position: relative;">
                                                <ng-container *ngIf="url.docType ==='client'  ">
                                                    <a href="{{url.Key}} " download class="preview thumbnail "   target="_blank" *ngIf="url.extension==='png' " href="{{url.Key}}">
                                                        <img src="{{url.Key}} " style="width: 95%;height: 95%; ">
                                                        <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>
                                                        
                                                    </a>
                                                </ng-container>

                                                <ng-container *ngIf="url.docType ==='client' ">
                                                    <a href="{{url.Key}} " download class="preview thumbnail "   target="_blank" *ngIf="url.extension==='jpg' ">
                                                        <img src="{{url.Key}} " style="width: 95%; height: 95%;">
                                                        <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>
                                                    </a>
                                                </ng-container>
                                                <ng-container *ngIf="url.docType ==='client' ">
                                                    <a href="{{url.Key}} " download class="preview thumbnail "   target="_blank" *ngIf="url.extension==='jpeg' ">
                                                        <img src="{{url.Key}} " style="width: 95%; height: 95%;">
                                                        <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>
                                                    </a>
                                                </ng-container>
                                                <ng-container *ngIf="url.docType ==='client' ">

                                                    <a href="{{url.Key}} " download class="preview thumbnail pdfthumbnail "   target="_blank" *ngIf="url.extension==='pdf' ">
                                                        <!-- <pdf-viewer src="{{url.Key}} " [show-all]="false " [zoom]="0.12 " [render-text]="true " [page]="page " style="display: block; "></pdf-viewer> -->
                                                        <img src="../../../../assets/icons/pdf.png">
                                                        <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>
                                                    </a>
                                                </ng-container>

                                                <ng-container *ngIf="url.docType ==='client' ">

                                                    <a href="{{url.Key}} " download class="preview thumbnail "   target="_blank"  *ngIf="url.extension==='dwg' ">
                                                        <img src="../../../../assets/icons/dwg.png">
                                                        <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>
                                                    </a>
                                                </ng-container>
                                                <ng-container *ngIf="url.docType ==='client' ">
                                                    <a href="{{url.Key}} " download class="preview thumbnail "   target="_blank" *ngIf="url.extension==='docx' ">
                                                        <img src="../../../../assets/icons/doc.png ">
                                                        <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>
                                                    </a>
                                                </ng-container>

                                                <ng-container *ngIf="url.docType ==='client' ">
                                                    <a href="{{url.Key}} " download class="preview thumbnail "   target="_blank" *ngIf="url.extension==='doc' ">
                                                        <img src="../../../../assets/icons/doc.png">
                                                        <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>
                                                    </a>
                                                </ng-container>
                                                <ng-container *ngIf="url.docType ==='client' ">
                                                    <a href="{{url.Key}} " download class="preview thumbnail "   target="_blank" *ngIf="url.extension==='zip' || url.extension==='rar' ">
                                                        <img src="../../../../assets/icons/zip.png">
                                                        <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>
                                                    </a>
                                                </ng-container>
                                                <ng-container *ngIf="url.docType ==='client' ">
                                                    <a href="{{url.Key}} " download class="preview thumbnail "   target="_blank" *ngIf="url.extension==='xls'  ">
                                                        <img src="../../../../assets/icons/xls.png">
                                                        <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>
                                                    </a>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 content-box-col">
                <div class="content card card-container mt-4 mt-md-0" id="style-3">
                    <div class="faq card-body">
                        <p class="QA">Public Q&A</p>
                        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">

                            <li class="nav-item" (click)="closeFAQ()">
                                <a class="nav-link active" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="true">Answered</a>
                            </li>

                        </ul>
                        <div class="tab-content" id="pills-tabContent" style="overflow-y: auto;">
                           
                            <div class="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                <div *ngIf="closedfaqs.length !=0; else emptyState">
                                    <ng-container *ngFor="let faq of closedfaqs; let i = index">
                                        <div class="accordion" id="accordionExample">
                                            <div class="card" style="border: none;">
                                                <div class="card-header" id="headingOne">
                                                    <p style=" font-weight: 700;position: relative;"><span style="    font-size: 12px;
                                                    position: absolute;
                                                    bottom: 4px;
                                                    right: 0;
                                                    color: #b8bac7;">{{faq.faqs.createdAt | date:'short' : '-0800'}}</span> {{faq.faqs.engineer_name | titlecase}}</p>

                                                    <p style=" margin-bottom: 2px;font-size: 14px;   font-weight: 600;">Q{{ i + 1}}.{{faq.faqs.question}}</p>
                                                    <p style=" font-size: 14px;">Ans: {{faq.faqs.answer}}</p>
                                                </div>
    
                                                <div id="collapseOne" class="collapse " aria-labelledby="headingOne" data-parent="#accordionExample">
                                                    <div class="card-body">
                                                        {{faq.faqs.answer}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <ng-template #emptyState>
                                    <div style="display: flex;align-items: center;height: 38vh;">
                                        <div class="verified ">
                                            <app-emptystates [message] = "'No answered FAQ'"></app-emptystates>
                                        </div>
                                     </div>
                                </ng-template>
                                                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #elseBlock>
        <div style="display: flex;align-items: center;justify-content: center;height: 70vh;">
            <app-loading></app-loading>
        </div>
    </ng-template>
</div>

<!-- modal for send bids -->


<div class="modal fade" id="acceptmodel" tabindex="-1" role="dialog" aria-labelledby="acceptmodelTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog " role="document">
        <div class="modal-content">
            <div class="modal-header">
                <p class="model-title"> <b>{{details?.project.projectName | titlecase}}</b> </p>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><img src="../../../../assets/icons/cross.svg" alt=""></span>
               </button>
            </div>
            <div class="modal-body">
                <div class="input-group mb-3 groups text_box">
                    <div class="input-group-prepend ">
                        <span class="input-group-text" id="basic-addon3 " style="border: none;color: black;">Bid Value: $</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="bidammount" (keyup) = "plaformfee()" id="basic-url" aria-describedby="basic-addon3" style="background:#EFF4F7;">
                </div>
                <!-- <p style="    margin-left: 12px;">Platform fee: ${{platformFee}}</p>
                <p style="font-weight: 700;    margin-left: 12px;">Total bid: ${{commission}}</p> -->

                <!-- <label for="" class="subtitle" style="font-weight:500;"> What's the bid split-up ?</label>
                <textarea class="form-control" aria-label="With textarea" [(ngModel)]="splitup" style="background:#EFF4F7;height: 75px;"></textarea> -->

            </div>
            <div class="modal-footer ">

                <span *ngIf = "details?.bids.length == 0 " class="btn-model apply" data-dismiss="modal" (click)="sendbid()">
                    Submit Bid
                </span>
                <span *ngIf = " details?.bids[0]?.bids?.rebid == true" class="btn-model apply" data-dismiss="modal" (click)="sendrebid()">
                    Submit Rebid
                </span>

            </div>
        </div>
    </div>

</div>