import { LoadingBarService } from '@ngx-loading-bar/core';
import { AuthServiceService } from './../../../service/auth/auth-service.service';
import { ProjectService } from './../../../service/project/project.service';

import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild, AfterContentChecked } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

@Component({
  selector: 'app-activebids',
  templateUrl: './activebids.component.html',
  styleUrls: ['./activebids.component.scss']
})
export class ActivebidsComponent implements OnInit, AfterContentChecked {


  @ViewChild('placesRef', { static: false }) placesRef: GooglePlaceDirective;
  public option = {
    types: ['(cities)'],
    componentRestrictions: { country: 'IN' }
  };
  location;
  bids;
  public loading = true;
  public value :boolean;
  public bidammount;
  public splitup;
  public projectId;
  public name;
  public emptyprojects = " No active bids yet!";
  public rejectData = []
  public platformFee = 0;
  public commission = 0;

  public p = 1;
  public totalDocs = 1;
  public totalrejectedDocs = 1;

  constructor(private loadingBar: LoadingBarService,  private cdRef : ChangeDetectorRef,private projectService: ProjectService, private AuthService: AuthServiceService) { }

  ngOnInit(): void {
   this.getActiveBids();
   this.AuthService.header.next(true)
  }
  public handleAddressChange(address: Address) {
    this.location = address.formatted_address;
    console.log(address)
  }
  getActiveBids(){
    this.loadingBar.start(0);
    this.AuthService.avatarData.next(true)
    this.projectService.getActivebids(this.p).subscribe( (data :any)=> {
      this.loadingBar.stop();
      console.log(data)
      this.bids = data.activebid;
      this.totalDocs = data?.totalDocs[0]?.value;
      this.loading = false;
      this.value = false;
      
    },
    err => {
      this.loadingBar.stop();
      this.loading = false;
      this.value = true;
    })
    // this.getRejectbid()
  }
  sendId(index,id,name){
    this.name = name
    console.log(name)
   this.projectId = id;
   console.log(this.projectId)
  }

    sendbid(){
    let biddata = {
      "bids": {
        "bidAmount" :   this.bidammount,
        "commission": this.platformFee,
        "splitUp": ''
      }
    }
    console.log(biddata);
    this.loading = true;
    this.loadingBar.start(0)
    this.projectService.sendRebid(biddata,this.projectId).subscribe((res)=>{
      
      console.log(res);
      this.projectService.getActivebids(this.p).subscribe( (data :any)=> {
        this.loadingBar.stop()
        console.log(data)
        this.bids = data.activebid;
        this.loading = false;
        this.value = false;
       
      },
      err => {
        this.loadingBar.stop()
        this.loading = false;
        this.value = true;
      })
    })
     
  }
  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

  getRejectbid(){
    console.log('revsdfsdf');
    this.loadingBar.start(0);
    this.projectService.rejectbid(this.p).subscribe( (data: any) => {
      this.loadingBar.stop()
      this.rejectData = data.activebid;
      this.totalrejectedDocs = data?.totalDocs[0]?.value
      console.log(data);
    })
  }
  plaformfee() {

    if(this.bidammount <= 500) {
      this.platformFee =Math.round( Number((this.bidammount)/100) * 20)
      this.commission = Math.round(Number(this.bidammount) +  Number(this.platformFee))
    
    } else if(this.bidammount > 500 && this.bidammount <= 1000) {
      this.platformFee =Math.round( Number((this.bidammount)/100) * 15)
      this.commission = Math.round(Number(this.bidammount) +  Number(this.platformFee))
      
    } else {
      this.platformFee =Math.round( Number((this.bidammount)/100) * 10)
      this.commission = Math.round(Number(this.bidammount) +  Number(this.platformFee))

    }
  }

  pageChanged(event) {
    this.p = event
    this. getActiveBids()
  }

}
