import { SocketoneService, SockettwoService } from '../../service/socketconnection.service';
import { Component, OnInit, OnDestroy,  AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { DomSanitizer} from '@angular/platform-browser';
import { AuthServiceService } from '../../service/auth/auth-service.service';
declare var $ : any;

export interface SenderID {
  _id: string;
  username: string;
}

export interface ProjectID {
  status: string;
  _id: string;
  projectName: string;
}

export interface RootObject {
  status: string;
  _id: string;
  senderID: SenderID ;
  content: string;
  projectID: ProjectID;
  createdAt: string
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy, AfterContentChecked {

  public navigationLinks = false;
  public imageurl;
  public user;
  public notificationdata = [];
  public username;
  public projectNotifyData:RootObject[] = []
  public isNotification = false
  public isHeader

  color = false

  constructor(private sanitizer: DomSanitizer,
    private socket: SocketoneService,
    private socket2: SockettwoService ,
    private cookie: CookieService,
    private service: AuthServiceService,
    private router: Router,
    private cdref: ChangeDetectorRef
    ) {

  }

  ngOnInit(): void {
    this.service.showHeader.subscribe(data => {

      console.error(data, "headrrr EMIT");
      
      if(this.cookie.check('register') ) {
        this.isHeader = false
      } else {
        this.isHeader = data
      }
      this.cdref.detectChanges()

    })

    // if (this.cookie.check('token')) {
    //   // console.log(`jwt ${this.cookie.get('token')}`);
    //   // this.socket.emit('joinRoom',{'token': this.cookie.get('token'),'owner': 'engineer' })
    //   // this.socket.on('isReconnected', data => {
    //   //   console.log(data);
    //   //   this.socket.emit('tryReconnect', {'token': this.cookie.get('token'),'owner': 'engineer' })    
    //   // })
    //   // this.socket2.on('isReconnected', data => {
    //   //   console.log(data);
    //   //   this.socket2.emit('tryReconnect', {'token': this.cookie.get('token')})    
    //   // })
    //   // this.getProfileData()
      
    // }
    // else {
    //   console.log('no token');
    // }


    this.service.profileData.subscribe((data) => {
      console.log(data);
      this.imageurl = data.avatar;
      this.username = data.username;
      if(data.block == true) {
        console.error("df")
        $("#blockModal").modal('show')
      } else {
        console.error("dfsdf")
        $("#blockModal").modal('hide')
      }
      
    });



    this.socket.on('headerMessage', data => {
      console.log('notification')
      console.log(data);
      let tempArr = []
      if (data.message.owner == 'client') {
        tempArr.push(data)
      }
      this.squash(tempArr)
    })

    this.socket.on('initialNotify', data => {
      console.log(data);
      this.projectNotifyData = data.notifications.reverse();
      console.log(this.projectNotifyData)
      data.notifications.forEach(element => {
       
        
        if (element.status == "unread") {
          this.isNotification = true;
        }
      });

    });
    this.socket.on('initialMessageNotify', data => {
      console.log(data);
      this.notificationdata = data.allUnreadMessages
      this.notificationdata.forEach(element => {
        if (element.message.status == "unread") {
          this.isNotification = true;
         
        }
      });
     
    });

    this.socket.on('singleNotify', data => {
      console.log(data);
      this.isNotification = true;

      let singleYour = this.projectNotifyData.some(arrData => {
        return arrData.projectID._id.includes(data.projectID._id)
      })
      console.log(singleYour);
      if(singleYour) {
        this.projectNotifyData.unshift(data)
        for( let i = 0; this.projectNotifyData.length > 0; i++) {
          if(this.projectNotifyData[i].projectID._id == data.projectID._id) {
            this.projectNotifyData[i].projectID.status = data.projectID.status;
            console.log('hello');
          }
        }
        
      } else {
        
        this.projectNotifyData.unshift(data)
      }

      
    });

    this.socket2.on('adminNotify', data => {
      console.log(data);
      if(data.content == "Admin has blocked you") {
        $("#blockModal").modal('show')
      } else {
        $("#blockModal").modal('hide')

      }
      this.isNotification = true;
      this.projectNotifyData.unshift(data)
    })

    this.socket.emit('getIntialNotify', {
      'token': this.cookie.get('token'),
      'owner': 'engineer'
    })

    this.socket.on('engineerJoin', (data) => {
      console.log('string runs');

      this.socket.emit('joinSingleProject', {
        'projectID': data.projectID,
        'owner': 'engineer'
      })
    })

  }

  ngAfterContentChecked() {

    this.cdref.detectChanges();

  }


  goto(id, index) {
    console.log(id);
    console.log(index);
    
    
    this.socket.emit('readNotification', {
      'notificationID': id,
      'owner': 'engineer'
    })

    this.socket.on('readNotificationResponse', (response) => {
      console.log(response);
      
      if (response == true) {
        console.log(this.projectNotifyData);
        
        this.projectNotifyData[index].status = 'read';
        // const yoyo = this.projectNotifyData.some(proj => proj.status.includes('unread'))
        // this.isNotification = yoyo;
        // this.notificationdata.splice(index, 1)
        console.log('sadfsdf');
        
      

      }
    
      console.log(  this.isNotification);
      
    })
  }

  squash(arr) {
    this.notificationdata = [];
    for (var i = 0; i < arr.length; i++) {
      if (this.notificationdata.indexOf(arr[i]) == -1) {
        this.notificationdata.push(arr[i]);
      }
    }
    this.notificationdata.unshift();
    this.notificationdata.forEach(element => {
      if (element.message.status == "unread") {
        this.isNotification = true;
        return true;
      }
    });
  }

  logout() {
    this.service.logout().subscribe(() => {
      this.cookie.deleteAll('/');
      this.router.navigateByUrl('/onboard/login');
      this.service.showHeader.next(false)
      this.service.profileData.next({})


    })
  }

  test(e) {
    console.log(e.target);
    e.preventDefault()
    e.stopPropagation();
    $(e.target).tab('show')
  }

  ngOnDestroy() {
    // this.service.headerColor.unsubscribe();
    // // this.color = false;
    this.projectNotifyData = []
    this.notificationdata = []
  }

  clearNotification() {
    this.service.clearNotifications().subscribe((data: any) => {
      console.log(data);
      
      this.projectNotifyData = data?.notifications.notifications.reverse()
      this.notificationdata = []
    })
  }

  notifyCounter() {
    let count = 0;
    for(let i = 0; i < this.projectNotifyData.length; ++i){
        if(this.projectNotifyData[i].status == 'unread') 
            count++;
    }
    if(count + this.notificationdata?.length == 0) {
      this.isNotification = false;
    }

    if(count + this.notificationdata?.length == 0) {
      this.isNotification = false
    }

    return count + this.notificationdata?.length;
  }

  removeBtn() {
    console.log("sdfsdf");
    
    $('#navbarSupportedContent').collapse("hide");
  }

}
