import { LoadingBarService } from '@ngx-loading-bar/core';
import { CookieService } from 'ngx-cookie-service';
import { ProjectService } from './../../../service/project/project.service';
import { AuthServiceService } from './../../../service/auth/auth-service.service';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';


@Component({
  selector: 'app-browseproject',
  templateUrl: './browseproject.component.html',
  styleUrls: ['./browseproject.component.scss']
})
export class BrowseprojectComponent implements OnInit {

  public location = '';
  public Projects;
 
  public loading = true;
  public Projectname;
 
 
  public licenseType = '';
 
  public resetBtn = false;
  public bidammount;
  public splitup;
  projectId;
  emptyprojects = " There are no projects available."
 
 
  validdata: boolean;
 
  public platformFee = 0;
  public commission = 0;
  p: number = 1;
  public totalDocs = 1;

  isfilterApplied = false
 
  public totalProjects: number;
  states = ["Alaska",
  "Alabama",
  "Arkansas",
  "American Samoa",
  "Arizona",
  "California",
  "Colorado",
  "Connecticut",
  "District of Columbia",
  "Delaware",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Iowa",
  "Idaho",
  "Illinois",
  "Indiana",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Massachusetts",
  "Maryland",
  "Maine",
  "Michigan",
  "Minnesota",
  "Missouri",
  "Mississippi",
  "Montana",
  "North Carolina",
  " North Dakota",
  "Nebraska",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "Nevada",
  "New York",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Virginia",
  "Virgin Islands",
  "Vermont",
  "Washington",
  "Wisconsin",
  "West Virginia",
  "Wyoming"]
 
  constructor(private loadingBar: LoadingBarService, private cookie: CookieService, private AuthService: AuthServiceService, private projectService: ProjectService, private router: Router) {}
 
  ngOnInit(): void {
    
    this.cookie.delete('register', '/')
    setTimeout(()=> {
      console.warn("cookie dltd");
      
      this.AuthService.showHeader.next(true)
      this.loading = true
      this.Allbrowse(this.isfilterApplied);
    },10)
  }
 
  public handleAddressChange(address: Address) {
    this.location = address.formatted_address;
    console.log(this.location)
  }
 
  public apply() {
    if (this.location != '' || this.licenseType != '') {
      // this.loading = true;
      // this.resetBtn = true;
      this.isfilterApplied = true;
      this.Allbrowse(this.isfilterApplied);
     
 

    }
  }
 
 
  public Reset() {
    this.location = ''
    this.licenseType = ''
    this.isfilterApplied = false;
    this.Allbrowse(this.isfilterApplied);
  }
 
  sendId(index, id, projectname) {
    this.Projectname = projectname
    console.log(index)
    this.projectId = id;
  }
 
  Allbrowse(filter) {
    console.log('filter applied', filter);
    
    this.loadingBar.start(0)
    
    this.projectService.getBrowse(this.p,this.licenseType,this.location).subscribe((data: any) => {
      window.scrollTo(0,0)

      this.loadingBar.stop()
        console.log(data)
 
        if (data?.project.length === 0) {
          this.loading = false;
        
          this.Projects = data.project
          this.totalDocs = data?.totalDocs[0]?.value
          this.resetBtn = filter;
          console.log(this.resetBtn, "changed");
          
          
        } else {
          this.Projects = data.project
          this.totalDocs = data?.totalDocs[0]?.value
          this.loading = false;
          if(filter == true) {
            this.resetBtn = filter;
           } else {
            this.resetBtn = filter;
  
           }
        }
 
      },
      err => {
        this.loading = false;
        this.loadingBar.stop()
     
        if(filter == true) {
          this.resetBtn = true;
         } else {
          this.resetBtn = false;

         }
      })
  }
  navigate(routeid) {
    console.log(routeid)
    this.router.navigate(['dashboard/browseprojects/' + routeid])
  }
 
  sendbid() {
    let biddata = {
      "bids": {
        "bidAmount": Number(this.bidammount),
        "commission": Number(this.platformFee),
        "splitUp": ''
      }
    }
    console.log(biddata);
    this.loading = true;
    this.projectService.sendBid(biddata, this.projectId).subscribe((res) => {
      console.log(res);
      // this.Allbrowse();
      this.router.navigate(['/dashboard/activebids'])
    })
 
  }
 
  resetValue() {
    this.location = '';
    this.licenseType = '';
  }
  plaformfee() {
 
    if (this.bidammount <= 500) {
      this.platformFee = Math.round(Number((this.bidammount) / 100) * 20)
      this.commission = Math.round(Number(this.bidammount) + Number(this.platformFee))
 
    } else if (this.bidammount > 500 && this.bidammount <= 1000) {
      this.platformFee = Math.round(Number((this.bidammount) / 100) * 15)
      this.commission = Math.round(Number(this.bidammount) + Number(this.platformFee))
 
    } else {
      this.platformFee = Math.round(Number((this.bidammount) / 100) * 10)
      this.commission = Math.round(Number(this.bidammount) + Number(this.platformFee))
 
    }
  }
 
  pageChanged(event) {
   this.p = event;
   
   this.Allbrowse(this.isfilterApplied);
  }
}
