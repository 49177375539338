import { CookieService } from 'ngx-cookie-service';
import { FormGroup, FormControl, FormArray, FormBuilder, Validator, Validators } from '@angular/forms';
import { HttpHeaders } from '@angular/common/http';
import { AuthServiceService } from './../../../service/auth/auth-service.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';
import { ConfirmedValidator } from 'src/app/validator/confirmed.validator';
import { LoadingBarService } from '@ngx-loading-bar/core';

declare var $ : any;
@Component({
  selector: 'app-viewprofile',
  templateUrl: './viewprofile.component.html',
  styleUrls: ['./viewprofile.component.scss']
})
export class ViewprofileComponent implements OnInit {

	public past_dates = new Date().toISOString().substring(0, 10)

	public submit = true;
	public loading = false;
	public avatar = false;
	public imagePath;
	public imgURL: any = "../../../../assets/other/user.svg";
	public styles = {
		backgroundImage: `url(../../../../assets/other/user.svg)`
	};
	
	files = [];
	public inputArr = [];
	public Increment = 0;
	urls = []
	model: any = {};
	modelPass: any = {};
	fileListARR = [];
	public docs = [];
	public SelectFileArr = [];
	public filepush;
	public editDetail = true

	// public new_locationArr =[]
	// public new_licenseArr =[]
	// public new_numberArr =[]
	// public new_dateArr =[]

	
	public response;

	states = ["Alaska",
	"Alabama",
	"Arkansas",
	"American Samoa",
	"Arizona",
	"California",
	"Colorado",
	"Connecticut",
	"District of Columbia",
	"Delaware",
	"Florida",
	"Georgia",
	"Guam",
	"Hawaii",
	"Iowa",
	"Idaho",
	"Illinois",
	"Indiana",
	"Kansas",
	"Kentucky",
	"Louisiana",
	"Massachusetts",
	"Maryland",
	"Maine",
	"Michigan",
	"Minnesota",
	"Missouri",
	"Mississippi",
	"Montana",
	"North Carolina",
	" North Dakota",
	"Nebraska",
	"New Hampshire",
	"New Jersey",
	"New Mexico",
	"Nevada",
	"New York",
	"Ohio",
	"Oklahoma",
	"Oregon",
	"Pennsylvania",
	"Puerto Rico",
	"Rhode Island",
	"South Carolina",
	"South Dakota",
	"Tennessee",
	"Texas",
	"Utah",
	"Virginia",
	"Virgin Islands",
	"Vermont",
	"Washington",
	"Wisconsin",
	"West Virginia",
	"Wyoming"]

	public dummyData;

	myforms: FormGroup = new FormGroup({});

	
	constructor(private loadingBar: LoadingBarService, private cookie: CookieService, private fb: FormBuilder, private authservice: AuthServiceService, private sanitizer: DomSanitizer) {}
	
	ngOnInit(): void {
		
		this.authservice.avatarData.next(true)
		this.authservice.header.next(true)
		this.loading = true;
		this.loadingBar.start(0)
		this.authservice.viewprofile().subscribe((data: any) => {
			console.log(data);
			
			this.loadingBar.stop()
			this.loading = false;
			this.response = data;
			this.dummyData = data.profession;
			console.log(data.profession)

			if (data.avatar) {
				let TYPED_ARRAY = new Uint8Array(data.avatar.data);
				const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
				  return data + String.fromCharCode(byte);
				}, '');
		  
				let base64String = btoa(STRING_CHAR);
				this.imgURL = this.sanitizer.bypassSecurityTrustUrl('data:image/jpg;base64, ' + base64String);
				this.styles = {
				  backgroundImage: `url(${this.imgURL})`
				};
				console.log(this.styles.backgroundImage)
			  } else {
				this.imgURL = "../../../../assets/other/user.svg";
			  }
			  this.getDefaultDate(data.profession[0]?.expDate)

			this.myforms = this.fb.group({
  
				username: new FormControl(data.profile.username, [Validators.required, Validators.minLength(6)]),
				description: new FormControl(data.profile.description, [Validators.required]),
				insuranceDoc: new FormControl(null, []),
				license: new FormArray([
				//   new FormControl(data.profession[0].licence_type, [Validators.required])
				]),
				location: new FormArray( []),
				// documents: new FormArray([
				//   new FormControl(null, [Validators.required])
				// ]),
				licenseNumber: new FormArray([
					// new FormControl(data.profession[0].licence_num, [Validators.required])
				  ]),
				expDate: new FormArray([
					// new FormControl( this.getDefaultDate(data.profession[0]?.expDate), [Validators.required])
				  ])
		  
			});
			if(data.profile.profession.length > 0) {
                for (let index = 0; index < data.profession.length; index++) {
					( < FormArray > this.myforms.get('license')).push(new FormControl(data.profession[index ]?.licence_type, [Validators.required]));
					( < FormArray > this.myforms.get('location')).push(new FormControl(data.profession[index ]?.state, [Validators.required]));
					( < FormArray > this.myforms.get('expDate')).push(new FormControl( this.getDefaultDate(data.profession[index ]?.expDate ), [Validators.required]));
					( < FormArray > this.myforms.get('licenseNumber')).push(new FormControl(data.profession[index ]?.licence_num, [Validators.required]));		
				}
			}
	
		})
		
	}

	getnewLocationArr(data) {
		console.log(data);
		
      let newArr = [];
	  for (let index = 0; index < data?.length; index++) {
		//   const element = data[index];
		newArr.push(new FormControl(data[index]?.state, [Validators.required]))
		  
	  }
	  console.log(newArr);
	  return newArr
	  
	}

	getDefaultDate(data) {
		console.log(data);
		

		var d = new Date(data);


		const year =  d.getFullYear()

		const month = `${ d.getMonth() + 1}`.padStart(2, "0")

		const day = `${ d.getDate()}`.padStart(2, "0")

		return `${year}-${month}-${day}`

		
	}
	
	
	changecall() {
	
		this.authservice.getPaymentURL().subscribe((data: any) => {
			window.open(data.url.url, "_self")
			console.log(data);
	
		})
	}

		// convenience getter for easy access to form fields
		get f() {
			return this.myforms.controls;
		}
		
	  
		  getLocationArr() {
			return (this.myforms.get('location') as FormArray).controls;
		  }
	  
		  addInput() {
		
			( < FormArray > this.myforms.get('license')).push(new FormControl(null, [Validators.required]));
			( < FormArray > this.myforms.get('location')).push(new FormControl(null, [Validators.required]));
			( < FormArray > this.myforms.get('expDate')).push(new FormControl(null, [Validators.required]));
			( < FormArray > this.myforms.get('licenseNumber')).push(new FormControl(null, [Validators.required]));
	  
		
		  }
		
		  deleteFeild(i) {
		
			( < FormArray > this.myforms.get('license')).removeAt(i);
			( < FormArray > this.myforms.get('location')).removeAt(i);
			( < FormArray > this.myforms.get('expDate')).removeAt(i);
			( < FormArray > this.myforms.get('licenseNumber')).removeAt(i);
			
	  
			this.SelectFileArr.splice(i, 1)
			this.urls.splice(i, 1)
			this.docs.splice(i, 1)
			this.fileListARR.splice(i, 1)
		
			for (let j = 0; j < this.SelectFileArr.length; j++) {
			  this.SelectFileArr[j].index = j;
			  this.urls[j].fileIndex = j;
			  this.docs[j].fileIndex = j;
			  this.fileListARR[j].fileIndex = j;
			  console.log(this.SelectFileArr[j]);
			}
		
			console.log(this.SelectFileArr);
			console.log(this.urls);
			console.log(this.fileListARR);
		
		
		  }
		  async detectFiles(event) {
			this.files = event.target.files;
			  console.log(event.target.files[0].name.split('.').pop())
	
			  if (this.files) {
				for (let file of this.files) {
				  const toBase64 = file => new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = () => resolve(reader.result);
					reader.onerror = error => reject(error);
				});
				  await toBase64(file).then( data => {
					let newFile = {
					  'FileValue': data,
					  'FileType' : file.name.split('.').pop(),
					  'Filename' : file.name
					}
					this.urls.push(newFile);
					console.log(this.urls)
				  })
				}
			  }
		  
			  Array.from(this.files).forEach((singleFile) => {
					 
				this.filepush = {
				  'fileType' : singleFile.type,
				  'extension' : singleFile.name.split('.').pop(),
				  'originalName': singleFile.name
				}
				  console.log(this.filepush)
				  this.docs.push(this.filepush);
				  this.fileListARR.push(singleFile)
			  })
			  console.log(this.docs); 
			  console.log(this.fileListARR);
			  
		  }

		  deleteDoc(index) {
			console.log(index)
			this.docs.splice(index, 1);
			this.SelectFileArr.splice(index, 1)
			this.urls.splice(index, 1)
			this.fileListARR.splice(index, 1)
			console.log(this.docs);
			console.log(this.fileListARR);
			console.log(this.urls);
			
		  }
		  readURL(files) {
			console.log(files.target.files[0]);
		
			if (files.length === 0)
			  return;
			  if( Math.round((files.target.files[0].size))  > 10485760) {
				  alert( 'The file size can not exceed 10MB.')
				  return
			  }
			  let fileName = files.target.files[0].name.split('.').pop();
	  
			  if(fileName == 'jpg' || fileName == 'jpeg' || fileName == 'png' ) {
				  this.avatar = true;
				  console.log(this.avatar)
			  
				  var reader = new FileReader();
				  this.imagePath = files;
				  reader.readAsDataURL(files.target.files[0]);
			  
				  reader.onload = (_event) => {
					this.imgURL = reader.result;
					console.log(this.imgURL)
					this.styles = {
					  backgroundImage: `url(${this.imgURL})`
					};
				  }
			  } else {
				  return alert('Please select valid image.')
			  }
	  
			  
			
	  
		
		  }

	startEdit() {
	  this.editDetail = false
	  console.log(this.myforms);
	}

	onSubmit() {

		this.loadingBar.start(0)
		console.log(this.myforms);
	
	
		// $('#exampleModal').modal('show');
		console.log(this.avatar);
		console.log(this.model);
	
		const formData = new FormData();
	
		formData.append('username', this.myforms.value.username);
		formData.append('description', this.myforms.value.description);
        if(this.myforms.value.insuranceDoc != null) {
			formData.append('fileType',this.docs[0].fileType);
			formData.append('extension',this.docs[0].extension);
			formData.append('fileName',this.docs[0].originalName);
		}
  
		for (let index = 0; index < ( < FormArray > this.myforms.get('license')).value.length; index++) {
			formData.append('Details[' + index + '][licence_type]', ( < FormArray > this.myforms.get('license')).value[index]);
			formData.append('Details[' + index + '][state]', ( < FormArray > this.myforms.get('location')).value[index]);
			formData.append('Details[' + index + '][licence_num]', ( < FormArray > this.myforms.get('licenseNumber')).value[index]);	  
			formData.append('Details[' + index + '][expDate]', ( < FormArray > this.myforms.get('expDate')).value[index]);	  
		}
		if (this.avatar == true) {
			formData.append('avatar', this.imagePath.target.files[0])
		}
  
		this.authservice.updateprofile(formData).subscribe((response: any) => {
			console.log(response)


			if( this.myforms.value.insuranceDoc != null) {
				console.log('no docs');
				
				const httpOptions = {
					headers: new HttpHeaders({
					'Content-Type': response?.documents.fileType
					})
				};
		
				this.authservice.sendKey(response?.documents.url, this.fileListARR[0], httpOptions).subscribe(data => {
					console.log(data)
		
					const httpOptions = {
					headers: new HttpHeaders({
						'Authorization': this.cookie.get('token')
					})
					};
		
					const value = {
						'key': response.documents.key,
						'url': response.documents.url,
						'extension': response.documents.extension,
						'fileName': response.documents.fileName,
					}
					console.log(value);
		
					this.authservice.sendURL(value, httpOptions).subscribe(data => {
						this.loadingBar.stop()
						location.reload();
		
		  
					}, err =>  this.loadingBar.stop());
				})
			} else {
				this.editDetail = true;
				console.log('yes docs');
				this.loadingBar.stop()
				location.reload();
				// $('#exampleModal').modal('hide')
			}
  
		}, err => {
			this.loadingBar.stop()
			// $('#exampleModal').modal('hide')
		})
	

	
	  }

	  resetpass(){
		console.log('pass');
		$('#passwordModal').modal('hide');
		// $('#exampleModal').modal('show');
		this.loadingBar.start(0)
		var update = new FormData();
		update.append('password', this.modelPass.password)
		update.append('old_password', this.modelPass.oldPassword)
		
		this.authservice.updateprofile(update).subscribe((data: any) => {
			console.log(data)
			this.loadingBar.stop()
		}, ()=>this.loadingBar.stop())
       
		
	  }
	

	
}
