import { SocketoneService } from './../../../service/socketconnection.service';
import { ProjectService } from './../../../service/project/project.service';
import { AuthServiceService } from './../../../service/auth/auth-service.service';
import { DomSanitizer } from '@angular/platform-browser';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Socket } from 'ngx-socket-io';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Component({
  selector: 'app-projectdetails',
  templateUrl: './projectdetails.component.html',
  styleUrls: ['./projectdetails.component.scss']
})
export class ProjectdetailsComponent implements OnInit {
  public details;
  public question ="";
  public closedfaqs = [];
  public newFAQ = [];
  public clientURL ;
  public fakeFaQ = []
  public loading = true;
  public show:boolean = false;
  public buttonName = 'question';
  public projectId;
  public bidammount;
  public splitup;
  public page = 1;
  public postDisable = false;
  public platformFee = 0;
  public commission = 0;

  constructor(private loadingBar: LoadingBarService, private sanitizer: DomSanitizer, private AuthService:AuthServiceService, private service: ProjectService, private actRoute: ActivatedRoute, private router: Router ) { }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      // do your task for before route
     console.log('do your task for before route');
     
      return false;
    }
    this.AuthService.avatarData.next(true)
    this.AuthService.header.next(true)

    const id = this.actRoute.snapshot.paramMap.get('id');
    console.log(id);
    this.loadingBar.start(0)
    
    this.service.browseDetail(id).subscribe( (data: any) => {
      this.loadingBar.stop()
      console.log(data)
      if(typeof(data?.clientAvatar) != 'undefined'){
        let TYPED_ARRAY = new Uint8Array(data.clientAvatar.data);
        const STRING_CHAR = TYPED_ARRAY.reduce((data,byte) =>{
          return data + String.fromCharCode(byte);
        },'');
  
        let base64String = btoa(STRING_CHAR);
        this.clientURL =   this.sanitizer.bypassSecurityTrustUrl('data:image/jpg;base64, ' + base64String );
        console.log(this.clientURL);
      }
      this. loading = false;
      this.details = data;
      this.newFAQ = data.faq;
      this.projectId = data.project._id
    }, err =>   this.loadingBar.stop())
  }
  toggle() {
    this.show = !this.show;

    // CHANGE THE NAME OF THE BUTTON.
    if(this.show)  {
      this.buttonName = 'questionOn';
      console.log(this.buttonName);
    } else {
      this.buttonName = 'question';
      console.log(this.buttonName);
    }    
  }
  postfaq(){
    if(this.question != ""){
      const id = this.actRoute.snapshot.paramMap.get('id');
      console.log(this.question)
  
      let datanew = { 'faqs': {
        'question' : this.question 
      }};
      this.postDisable = true;
      console.log(datanew)
      this.service.postFAQ(datanew,id).subscribe((data => {
        this.postDisable = false;
        console.log(data);
        this.newFAQ.push(datanew);
        this.question = ''
       
      }), err =>this.postDisable = false )
    }
  }

  closeFAQ(){
    this.loadingBar.start(0)
    const id = this.actRoute.snapshot.paramMap.get('id');
    console.log(id)
    this.service.closedFAQ(id).subscribe( (data :any) => {
      console.log(data);
      this.loadingBar.stop()
      this.closedfaqs = data.faq;
    })
  }
  newFAQS(){
    this.loadingBar.start(0)

    const id = this.actRoute.snapshot.paramMap.get('id');
    console.log(id)
    this.service.newFAQ(id).subscribe( (data :any) => {
      this.loadingBar.stop()

      console.log(data);
      this.newFAQ = data.faq;
    })

  }

  sendbid(){
    let biddata = {
      "bids": {
        "bidAmount" :  Number(this.bidammount),
        "commission": Number(this.platformFee),
        "splitUp": ''
      }
    }
    console.log(biddata);
    this.loading = true;
    this.loadingBar.start(10)
    this.service.sendBid(biddata,this.projectId).subscribe((res)=>{
      this.loadingBar.stop()
      this.router.navigate(['/dashboard/activebids'])
      console.log(res);
      
    }, err => this.loadingBar.stop())
     
  }
  sendrebid(){
    let biddata = {
      "bids": {
        "bidAmount" :  Number(this.bidammount),
        "commission": Number(this.platformFee),
        "splitUp": ''
      }
    }
    console.log(biddata);
    this.loading = true;
    this.loadingBar.start(0)
    this.service.sendRebid(biddata,this.projectId).subscribe((res)=>{
      this.loadingBar.stop()
      this.router.navigate(['/dashboard/activebids'])
      console.log(res);
      
    },  err => this.loadingBar.stop())
  }

  ngOnDestroy(){
    // this.AuthService.notificationID.unsubscribe();
  }

  plaformfee() {

    if(this.bidammount <= 500) {
      this.platformFee =Math.round( Number((this.bidammount)/100) * 20)
      this.commission = Math.round(Number(this.bidammount) +  Number(this.platformFee))
    
    } else if(this.bidammount > 500 && this.bidammount <= 1000) {
      this.platformFee =Math.round( Number((this.bidammount)/100) * 15)
      this.commission = Math.round(Number(this.bidammount) +  Number(this.platformFee))
      
    } else {
      this.platformFee =Math.round( Number((this.bidammount)/100) * 10)
      this.commission = Math.round(Number(this.bidammount) +  Number(this.platformFee))

    }
  }
  getFullLocation(data1, data2, data3, data4) {

    return `${data1}, ${data2}, ${data3}, ${data4}`

  }


}
