<!-- <app-header></app-header> -->

<div class="main-container" style="padding-top: 72px;">
    <ngx-loading-bar [includeSpinner] = false [color]="'#0066FF'"></ngx-loading-bar>
    <div class="container" *ngIf="loading == false; else elseBlock">
        <div class="contant-box">
               
            <div class="row top-box">
                <div class="col-xl-7">
                    <nav>
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                            <a (click) = "getActiveBids()" class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Active bids</a>
                            <a (click) = "getRejectbid()" class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Rejected bids</a>
                        </div>
                    </nav>
                </div>

            </div>
           

            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <div *ngIf="bids.length != 0; else emptyBids" class="card-container">
                        <div class="card" *ngFor="let bid of bids | reverse | paginate: { itemsPerPage: 5, currentPage: p, totalItems:totalDocs }; let i = index">
                            <div class="card-body">
                                <div class="row" routerLink="/dashboard/activebids/{{bid.projectID}}">
                                    <div class="col-8">
                                        <h5> {{bid.project[0].name | titlecase}} </h5>
                                        <p class="location">Project Location: <span>  {{bid.project[0].location }}</span></p>
                                    </div>
                                    <div class="col-4">
    
                                        <div class="totalbid" *ngIf = " bid.totalbids_received == 0 ||  bid.totalbids_received == 1">
                                            {{bid.totalbids_received}} bid
                                        </div>
                                        <div class="totalbid" *ngIf = " bid.totalbids_received > 1 ">
                                            {{bid.totalbids_received}} bids
                                        </div>
    
                                    </div>
                                </div>
                                <div class="row" routerLink="/dashboard/activebids/{{bid.projectID}}">
                                    <div class="col-xl-12">
                                        <p class="description">{{bid.project[0].description}}</p>
                                    </div>
                                </div>
                                <div class="row" *ngIf = "bid.bids.rebidReason">
                                    <div class="col-12">
                                        <p class="location" style="color: #F6566B;">Rebid Reason</p>
                                        <p class="description" style="margin-top: 5px;">{{bid.bids.rebidReason}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class=" col-lg-10 d-flex flex-wrap" > 
                                        <div class="details mr-md-3" >
                                            <div>
                                                <i-feather name="briefcase" class="big fill-red"></i-feather>
                                            </div>
                                            <div>
                                                <p class="title">License Type</p>
                                                <p class="value"> {{bid.project[0].licence | titlecase}}</p>
                                            </div>
                                        </div>
                                        
                                        <div class="details mt-3 mt-md-0 mr-md-3">
                                            <div >
                                                <i-feather name="dollar-sign" class="big"></i-feather>
                                            </div>
                                            <div>
                                                <p class="title" *ngIf="bid.bids.engineerAction == true && bid.bids.rebid == true || bid.bids.engineerAction == false && bid.bids.rebid == false  "> Bid Submitted</p>
                                                <p class="title" *ngIf="bid.bids.engineerAction == false && bid.bids.rebid == true " > Previous Bid Submitted</p>
                                                
                                                <p class="value">{{bid.bids.bidAmount}}</p>
                                            </div>
                                        </div>
                                        <div class="details mt-3 mt-md-0 mr-md-3">
                                            <div >
                                                <i-feather name="calendar" class="big"></i-feather>
                                            </div>
                                            <div>
                                                <p class="title"  >Posted on</p>
                                                
                                                <p class="value">{{bid.project[0].createdAt | date:'short' : '-0800'}}</p>
                                            </div>
                                        </div>
                                     
                                       
                                    </div>
                                    <div class="col-lg-2 " >
                                        <div class="previousbid mt-4 mt-lg-0" >
                                            <span class="submited" style="cursor: pointer;" *ngIf="bid.bids.engineerAction == false && bid.bids.rebid == true " data-toggle="modal" data-target="#acceptmodel" (click)="sendId(i,bid.projectID,bid.project[0].name)">
                                               Rebid Now
                                             </span>
                                     
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf = "totalDocs > 5" class="my-5 d-flex justify-content-center">
                            <pagination-controls (pageChange)=" pageChanged($event)"></pagination-controls>
                        </div>
                    </div>
                    <ng-template #emptyBids>
                        <div class=" card">
                            <div class="card-body">
                                <app-emptystates [message] = "emptyprojects"></app-emptystates>
                            </div>
                         </div>
                    </ng-template>
                    
                </div>
                <div  class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" >
                    <div *ngIf="rejectData.length != 0; else emptyrejectBids" class="card-container">
                        <div class="card" *ngFor="let bid of rejectData | reverse | paginate: { itemsPerPage: 5, currentPage: p, totalItems:totalrejectedDocs }; let i = index">
                            <div class="card-body"  [routerLink]="[ '/dashboard/activebids/reject/',bid.projectID]">
                                <div class="row" >
                                    <div class="col-8">
                                        <h5> {{bid.project[0].name | titlecase}} </h5>
                                        <p class="location">Project Location: <span>  {{bid.project[0].location}}</span></p>
                                    </div>
                                    <div class="col-4">
    
                                        <div class="totalbid" *ngIf = " bid.totalbids_received == 0 ||  bid.totalbids_received == 1">
                                            {{bid.totalbids_received}} bid
                                        </div>
                                        <div class="totalbid" *ngIf = " bid.totalbids_received > 1 ">
                                            {{bid.totalbids_received}} bids
                                        </div>
    
                                    </div>
                                </div>
                                <div class="row" >
                                    <div class="col-xl-12">
                                        <p class="description">{{bid.project[0].description}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-12 d-flex flex-wrap "  > 
                                        <div class="details" >
                                            <div>
                                                <i-feather name="briefcase" class="big fill-red"></i-feather>
                                            </div>
                                            <div>
                                                <p class="title">License Type</p>
                                                <p class="value"> {{bid.project[0].licence | titlecase}}</p>
                                            </div>
                                        </div>
                                       
                                        <div class="details mt-2 mt-md-0">
                                            <div>
                                                <i-feather name="dollar-sign" class="big"></i-feather>
                                            </div>
                                            <div>
                                                <p class="title" *ngIf="bid.bids.engineerAction == true && bid.bids.rebid == true || bid.bids.engineerAction == false && bid.bids.rebid == false  "> Bid Submitted</p>
                                                <p class="title" *ngIf="bid.bids.engineerAction == false && bid.bids.rebid == true " > Previous Bid Submitted</p>
                                                
                                                <p class="value">{{bid.bids.bidAmount }}</p>
                                            </div>
                                        </div>
                                        <div class="details mt-3 mt-md-0 mr-md-3">
                                            <div >
                                                <i-feather name="calendar" class="big"></i-feather>
                                            </div>
                                            <div>
                                                <p class="title"  >Posted on</p>
                                                
                                                <p class="value">{{bid.project[0].createdAt | date:'short' : '-0800'}}</p>
                                            </div>
                                        </div>

                                       
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div *ngIf = "totalrejectedDocs > 5" class="my-5 d-flex justify-content-center">

                            <pagination-controls (pageChange)=" pageChanged($event)"></pagination-controls>
                        </div>
                    </div>
                    <ng-template #emptyrejectBids >
                        <div class=" card">
                            <div class="card-body">
                                <app-emptystates message = "No rejected bids yet!"></app-emptystates>
                            </div>
                         </div>
                    </ng-template>
                </div>
            </div>
            

        </div>
        <!-- <ng-container *ngIf="bids.length != 0 || rejectData.length != 0  ; else templates">

          
        </ng-container>
        <ng-template #templates>
            <div class="card verified">
                <div class="card-body  ">
                    <app-emptystates [message] = "emptyprojects"></app-emptystates>
                </div>
             </div>
        </ng-template> -->
    </div>
    <ng-template #elseBlock>
        <div style="display: flex;align-items: center;justify-content: center;height: 70vh;">
            <app-loading></app-loading>
        </div>
    </ng-template>

</div>

<div class="modal fade" id="acceptmodel" tabindex="-1" role="dialog" aria-labelledby="acceptmodelTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog " role="document">
        <div class="modal-content">
            <div class="modal-header">
                <p class="model-title"><b>{{name | titlecase}}</b></p>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><img src="../../../../assets/icons/cross.svg" alt=""></span>
               </button>
            </div>
            <div class="modal-body">
                <div class="input-group mb-3 groups">
                    <div class="input-group-prepend ">
                        <span class="input-group-text" id="basic-addon3 " style="border: none;">Bid Value: $</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="bidammount" (keyup) = "plaformfee()" id="basic-url" aria-describedby="basic-addon3" style="background:#EFF4F7;">
                </div>
                <!-- <p  style="    margin-left: 12px;">Platform fee: ${{platformFee}}</p>
                <p  style="font-weight: 700;    margin-left: 12px;">Total bid: ${{commission}}</p> -->
                <!-- <label for="" class="subtitle"> What's the bid split-up ?</label>
                <textarea class="form-control" aria-label="With textarea" [(ngModel)]="splitup" style="background:#EFF4F7"></textarea> -->

            </div>
            <div class="modal-footer ">

                <span class="btn-model apply" data-dismiss="modal" (click)="sendbid()">
                    Submit bid
                </span>

            </div>
        </div>
    </div>

</div>

<!-- <div class="modal fade" id="acceptmodel" tabindex="-1" role="dialog" aria-labelledby="acceptmodelTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg " role="document">
        <div class="modal-content">
            <div class="modal-header">
                <p class="model-title">Projectname</p>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
               </button>
            </div>
            <div class="modal-body">
                <p class="subtitle">Estimate</p>
                <div class="input-group mb-3 groups">
                    <div class="input-group-prepend ammount">
                        <span class="input-group-text" id="basic-addon3">Enter your amount <span style="    margin-left: 8px;
                            font-size: 16px;
                            font-weight: 500;">$</span></span>
                    </div>
                    <input type="text" class="form-control" [(ngModel)]="bidammount" id="basic-url" aria-describedby="basic-addon3" style="color: #2067F8 ;">
                </div>
                <label for="" style="font-size: 15px; padding: 0 7px;"> What's the bid split-up ?</label>
                <textarea class="form-control" aria-label="With textarea" [(ngModel)]="splitup"></textarea>

            </div>
            <div class="modal-footer ">


                <button type="button apply" class="btn btn-primary " data-dismiss="modal" (click)="sendbid()">Submit bid</button>
            </div>
        </div>
    </div>

</div> -->