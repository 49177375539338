<!-- <app-header></app-header> -->

<div class="main-container">
    <ngx-loading-bar [includeSpinner] = false [color]="'#0066FF'"></ngx-loading-bar>

    <div class="container" *ngIf="loading == false; else elseBlock">
        <div class="heading-text">
            <p>{{details.project.projectName | titlecase}}<span class="image"><img src="../../../assets/icons/chevron-right.svg" alt=""></span> <span style="color:  #0066FF;">Project Details</span></p>
        </div>
        <div class="row content-box  pb-5">
            <div class="col-xl-8 col-lg-8 content-box-col">
                <div class="card card-container" id="style-3">
                    <div class="card-body">
                        <div class="responsive">
                            <div class=" statusandbid d-flex flex-wrap justify-content-between ">
                                <div >
                                    <p *ngIf="details?.project.status == 'open'" class="openbid">Open</p>
                                    <p *ngIf="details?.project.status == 'completed'" class="ongoingbid" style="background-color: #e6f9f7; color: #2DD6C4;">Completed</p>
                                    <p *ngIf="details?.project.status == 'ongoing'" class="ongoingbid">Ongoing</p>
                                </div>
                                <div >
                                <div class="completeButton d-flex flex-wrap  mt-md-0" *ngIf="details?.project.status == 'ongoing' ">
                                    
                                    <span class="mr-3 mb-2" style="color:white; background-color: #0066FF;"  *ngIf="getSubmitDocBtn(details?.project.engineer_submission)"   data-toggle="modal" data-target="#revisionModal">
                                        Submit Documents
                                    </span>
                                    <span class="mb-2" style="color:white; background-color: #0066FF;" (click) ="submitProject()" *ngIf="getSubmitProBtn(details?.project.engineer_submission)">
                                        Submit Project
                                    </span>
                                    <!-- <span class="mr-3" style="color:white; background-color: #92afdb;"  *ngIf="engineerDocs == true && details?.project.engineer_submission == true">
                                        Submit Project
                                    </span> -->
                                </div>
                                </div>
                            </div>
                            <h5>
                                {{details?.project.projectName | titlecase}}
                            </h5>

                            <div class="d-flex">
                                <div class="ml-3 pro">
                                    <img *ngIf="!details?.clientAvatar" class="engineerprofile" src="../../../../assets/other/user.svg" alt="">
                                    <img  *ngIf="details?.clientAvatar" class="engineerprofile" [src]="clientURL" alt="">
                                </div>
                                <div class="" style="margin-left: 13px;">
                                    <p class="name">{{details?.clientName | titlecase}}</p>
                                    <i-feather name="map-pin" style="width: 16px;
                                    height: 16px;"></i-feather> <span class="place">{{getFullLocation(details.project.addressLine1, details.project.addressLine2, details.project.city, details.project.state) | titlecase}}, USA</span>
                                </div>
                            </div>
                       
                            <p class="condition">Requirements</p>
                      
                           
                            <div class="row projectdetials">
                                <div class="col-xl-12 d-flex flex-wrap"> 
                                    <div class="details" >
                                        <div>
                                            <!-- <img src="../../../../assets/icons/education (1).svg" alt=""> -->
                                            <i-feather name="briefcase" class="big "></i-feather>

                                        </div>
                                        <div>
                                            <p class="title">License Type</p>
                                            <p class="value ">{{details?.project.licenseType | titlecase}}</p>
                                        </div>
                                    </div>

                                    <div class="details mt-2 mt-md-0">
                                        <div>
                                            <!-- <img src="../../../../assets/icons/doller.svg" alt="" style="width: 20px;"> -->
                                            <i-feather name="dollar-sign" class="big"></i-feather>


                                        </div>
                                        <div>
                                            <p class="title">Project Cost</p>
                                            <p class="value">{{details?.project.budget }} </p>
                                        </div>
                                    </div>
                                    <div class="details mt-2 mt-md-0">
                                        <div>
                                            <!-- <img src="../../../../assets/icons/doller.svg" alt="" style="width: 20px;"> -->
                                            <i-feather name="calendar" class="big"></i-feather>


                                        </div>
                                        <div>
                                            <p class="title">Posted on</p>
                                            <p class="value">{{details?.project.createdAt | date:'short' : '-0800' }} </p>
                                        </div>
                                    </div>
                             
                                   
                                </div>
                                
                            </div>
                            <div class="alert alert-warning" role="alert" *ngIf="details?.project.status == 'ongoing' && engineerDocs == true && details?.project.engineer_submission == 'true'">
                                This project has been submitted and is awaiting client's approval.
                            </div>
                            <hr>
                            <div class="row " *ngIf="(details?.project.status == 'ongoing' && engineerDocs == true) || details?.project.status == 'completed' && engineerDocs == true">
                                <div class="col-lg-6">
                                    <p class="condition" style="color: #0066FF; ">Documents Submitted By Engineer </p>
                                </div>
                                <div class="col-lg-6 engineerButtonGroup" *ngIf="details?.project.revisionStatus == true">
                                    <span class="engineerButton" style="background-color: #F6566B;" routerLink="/dashboard/revision/{{details?.project._id}}">
                                     View Revision
                                    </span>

                                </div>
                            </div>
                            <!--revision  Modal -->

                            <!--note  Modal -->
                            <div class="modal fade" id="noteModal" tabindex="-1" role="dialog" aria-labelledby="noteModallabel" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <p class="modal-title" id="noteModallabel" style="font-weight: 700;">Note</p>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true"><img src="../../../../assets/icons/cross.svg" alt="" style="width:15px"></span>
                                      </button>
                                        </div>
                                        <div class="modal-body">
                                            <div class="form-group">
                                                <p>There are revisions yet to be completed. Either close the revision or accept the revision before completing the project.</p>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <span data-dismiss="modal" style="background-color: #F6566B;">close</span>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div class="row projectdetials" *ngIf="(details?.project.status == 'ongoing' && engineerDocs == true) || details?.project.status == 'completed' && engineerDocs == true">
                                <div class="col-12 ">
                                    <div style="display: flex; ">
                                        <div class="uploads ">

                                            <div *ngFor="let url of details?.project_docs.docs ">
                                                <ng-container *ngIf="url.docType ==='engineer' ">
                                                    <a href="{{url.Key}}" download class="preview thumbnail "   target="_blank" *ngIf="url.extension==='png' ">
                                                        <img src="{{url.Key}} " style="width: 95%;height: 95%; ">
                                                        <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>


                                                    </a>
                                                </ng-container>

                                                <ng-container *ngIf="url.docType ==='engineer' ">
                                                    <a href="{{url.Key}}" download class="preview thumbnail "  target="_blank" *ngIf="url.extension==='jpg' ">
                                                        <img src="{{url.Key}} " style="width: 95%;height: 95%; ">
                                                        <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>


                                                    </a>
                                                </ng-container>
                                                <ng-container *ngIf="url.docType ==='engineer' ">
                                                    <a href="{{url.Key}}" download class="preview thumbnail "  target="_blank" *ngIf="url.extension==='jpeg' ">
                                                        <img src="{{url.Key}} " style="width: 95%;height: 95%; ">
                                                        <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>


                                                    </a>
                                                </ng-container>
                                                <ng-container *ngIf="url.docType ==='engineer' ">

                                                    <a href="{{url.Key}}" download class="preview thumbnail pdfthumbnail "  target="_blank" *ngIf="url.extension==='pdf' ">
                                                        <!-- <pdf-viewer src="{{url.Key}} " [show-all]="false " [zoom]="0.12 " [render-text]="true " [page]="page " style="display: block; "></pdf-viewer> -->
                                                        <img src="../../../../assets/icons/pdf.png ">
                                                        <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>


                                                    </a>
                                                </ng-container>

                                                <ng-container *ngIf="url.docType ==='engineer' ">

                                                    <a href="{{url.Key}}" download class="preview thumbnail "  target="_blank" *ngIf="url.extension==='dwg' ">
                                                        <img src="../../../../assets/icons/dwg.png ">
                                                        <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>


                                                    </a>
                                                </ng-container>
                                                <ng-container *ngIf="url.docType ==='engineer' ">
                                                    <a href="{{url.Key}}" download class="preview thumbnail "  target="_blank" *ngIf="url.extension==='docx' ">
                                                        <img src="../../../../assets/icons/doc.png ">
                                                        <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>


                                                    </a>
                                                </ng-container>

                                                <ng-container *ngIf="url.docType ==='engineer' ">
                                                    <a href="{{url.Key}}" download class="preview thumbnail "  target="_blank" *ngIf="url.extension==='doc' ">
                                                        <img src="../../../../assets/icons/doc.png ">
                                                        <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>

                                                    </a>
                                                </ng-container>
                                                <ng-container *ngIf="url.docType ==='engineer' ">
                                                    <a href="{{url.Key}}" download class="preview thumbnail "  target="_blank" *ngIf="url.extension==='zip' || url.extension==='rar' ">
                                                        <img src="../../../../assets/icons/zip.png ">
                                                        <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>

                                                    </a>
                                                </ng-container>
                                                <ng-container *ngIf="url.docType ==='engineer' ">
                                                    <a href="{{url.Key}}" download class="preview thumbnail "  target="_blank" *ngIf="url.extension==='xls'  ">
                                                        <img src="../../../../assets/icons/xls.png ">
                                                        <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>

                                                    </a>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <hr>
                            <p class="condition ">Project Description</p>
                            <p class="description ">
                                {{details?.project.description}}
                            </p>
                            <hr>
                            <p class="condition ">Project Documents</p>
                            <div class="row ">
                                <div class="col-12 ">
                                    <div style="display: flex; ">
                                        <div class="uploads ">

                                            <div *ngFor="let url of details?.project_docs.docs ">
                                                <ng-container *ngIf="url.docType ==='client' ">
                                                    <a href="{{url.Key}}" download class="preview thumbnail "  target="_blank" *ngIf="url.extension==='png' ">
                                                        <img src="{{url.Key}} " style="width: 95%; height: 95%; ">
                                                        <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>

                                                    </a>
                                                </ng-container>

                                                <ng-container *ngIf="url.docType ==='client' "> 
                                                    <a href="{{url.Key}}" download class="preview thumbnail "  target="_blank" *ngIf="url.extension==='jpg' ">
                                                        <img src="{{url.Key}} " style="width: 95%;height: 95%; ">
                                                        <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>

                                                    </a>
                                                </ng-container>
                                                <ng-container *ngIf="url.docType ==='client' ">
                                                    <a href="{{url.Key}}" download class="preview thumbnail "  target="_blank" *ngIf="url.extension==='jpeg' ">
                                                        <img src="{{url.Key}} " style="width: 95%;height: 95%; ">
                                                        <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>


                                                    </a>
                                                </ng-container>
                                                <ng-container *ngIf="url.docType ==='client' ">

                                                    <a href="{{url.Key}}" download class="preview thumbnail pdfthumbnail "  target="_blank" *ngIf="url.extension==='pdf' ">
                                                        <!-- <pdf-viewer src="{{url.Key}} " [show-all]="false " [zoom]="0.12" [render-text]="true " [page]="page " style="display: block; "></pdf-viewer> -->
                                                        <img src="../../../../assets/icons/pdf.png">
                                                        <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>


                                                    </a>
                                                </ng-container>

                                                <ng-container *ngIf="url.docType ==='client' ">

                                                    <a href="{{url.Key}}" download class="preview thumbnail "  target="_blank" *ngIf="url.extension==='dwg' ">
                                                        <img src="../../../../assets/icons/dwg.png">
                                                        <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>


                                                    </a>
                                                </ng-container>
                                                <ng-container *ngIf="url.docType ==='client' ">
                                                    <a href="{{url.Key}}" download class="preview thumbnail "  target="_blank" *ngIf="url.extension==='docx' ">
                                                        <img src="../../../../assets/icons/doc.png">
                                                        <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>


                                                    </a>
                                                </ng-container>

                                                <ng-container *ngIf="url.docType ==='client' ">
                                                    <a href="{{url.Key}}" download class="preview thumbnail "  target="_blank" *ngIf="url.extension==='doc' ">
                                                        <img src="../../../../assets/icons/doc.png">
                                                        <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>

                                                    </a>
                                                </ng-container>
                                                <ng-container *ngIf="url.docType ==='client' ">
                                                    <a href="{{url.Key}}" download class="preview thumbnail "  target="_blank" *ngIf="url.extension==='zip' || url.extension==='rar' ">
                                                        <img src="../../../../assets/icons/zip.png">
                                                        <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>

                                                    </a>
                                                </ng-container>
                                                <ng-container *ngIf="url.docType ==='client' ">
                                                    <a href="{{url.Key}}" download class="preview thumbnail "  target="_blank" *ngIf="url.extension==='xls'  ">
                                                        <img src="../../../../assets/icons/xls.png">
                                                        <p  style="margin-top: 15px;color: black;">{{url.originalName}}</p>

                                                    </a>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 content-box-col ">
                <div class="content card card-container mt-4 mt-lg-0">
                    <div class="chatbox ">
                        <div class="header ">
                            <div class="profileimage ">
                                <img  *ngIf="!details?.clientAvatar" src="../../../../assets/other/user.svg" alt=" ">
                                <img  *ngIf="details?.clientAvatar" [src]="clientURL " alt=" ">
                            </div>
                            <div class="name ">
                                <p>{{details?.clientName | titlecase}}</p>
                                <p>{{details?.project.location }}</p>
                            </div>
                        </div>
                        <div class="chatbody ">
                            <ng-container *ngFor="let message of messageArr; let i= index ">
                                <div class="receiver bottom " *ngIf="message.owner=='client' " (mouseenter)="mouseEnterevent(i) " (mouseout)="mouseLeaveevent(i) ">
                                    <!-- <p class="timeshowclient " *ngIf='hovershow == true && indexNumber == i'> {{message.time | date:'medium'}}</p> -->

                                    <div class="receivedmessage " [attr.id]="message._id ">
                                        {{message.message}}
                                        <br>
                                        <p class="mb-0 mt-2" style="    font-size: 10px;
                                        font-weight: 600;
                                        color: #000000cf;">{{message.time | date:'short' : '-0800'}}</p>

                                    </div>
                                </div>
                                <div class="sender bottom " *ngIf="message.owner=='engineer' " #scrollToMe (mouseenter)="mouseEnterevent(i) " (mouseout)="mouseLeaveevent(i) ">
                                    <!-- <p class="timeshowclient " *ngIf='hovershow == true && indexNumber == i'> {{message.time | date:'medium'}}</p> -->
                                    <div class="sendmessage  " [attr.id]="message._id ">
                                        {{message.message}}
                                        <br>
                                        <p class="text-right mb-0 mt-2" style="    font-size: 10px;
                                        font-weight: 600;
                                        color: #b2d1ff;">{{message.time | date:'short' : '-0800'}}</p>
                                    </div>


                                </div>

                            </ng-container>
                            <div id="bottomview" class="mb-3" #bottomview>

                            </div>
                        </div>


                        <div class="chatfooter " *ngIf="details?.project.status == 'ongoing'">
                            <form (keyup.enter)="sendmessage()">
                                <div class="input-group ">
                                    <input type="text " name="newmessage" [(ngModel)]="newmessage " class="form-control " autocomplete="off" placeholder="Type here " aria-label="Recipient 's username" aria-describedby="basic-addon2">
                                    <div class="input-group-append" (click)="sendmessage()">
                                        <span class="input-group-text" id="basic-addon2">Send</span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #elseBlock>
        <div style="display: flex;align-items: center;justify-content: center;height: 70vh;">
            <app-loading></app-loading>
        </div>
    </ng-template>
</div>


<!-- mode for sending docs -->

<div class="modal fade" id="revisionModal" tabindex="-1" role="dialog" aria-labelledby="revisionModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <p class="modal-title" id="sendDocsModel">Submit documents</p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true"><img src="../../../../assets/icons/cross.svg" alt="" style="width:15px"></span>
</button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label for="exampleInputEmail1 proHireSubHeading" style="font-weight: 700;">Upload Documents</label><span style="color: #F6566B;">*</span>
                    <div style="display: flex;">
                        <div class="uploads">
                            <div class="select preview">
                                <label for="test" class="filelabel">
                                    <div class="click">+</div>
                                    <input type="file" id="test" multiple (change)="detectFiles($event)" accept="image/jpeg,image/png,application/pdf,.doc,.docx,.dwg,.zip,.rar,.xls">
                                  </label>
                            </div>

                            <div *ngFor="let url of urls; let i = index">
                                <div class="preview thumbnail" *ngIf="url.FileType == 'png' || url.FileType == 'jpg' || url.FileType == 'jpeg'" style="position:relative">
                                    <i class="icon-close" (click) = "deleteDoc(i)"><img src="../../../../assets/icons/close.svg" alt=""></i>

                                    <img [src]="url.FileValue" style="width: 95%;height: 95%;">
                                    <p style="top:165px">{{url.Filename}}</p>
                                </div>

                                <div class="preview thumbnail pdfthumbnail" *ngIf="url.FileType == 'pdf'"  style="position:relative">
                                    <i class="icon-close" (click) = "deleteDoc(i)"><img src="../../../../assets/icons/close.svg" alt=""></i>

                                    <!-- <pdf-viewer src="{{url.FileValue}}" [show-all]="false" [zoom]="0.12" [render-text]="true" [page]="page" style="display: block;"></pdf-viewer> -->
                                    <img src="../../../../assets/icons/pdf.png ">
                                    <p style="top:165px">{{url.Filename}}</p>

                                </div>
                                <div class="preview thumbnail" *ngIf="url.FileType =='doc' || url.FileType =='docx' "  style="position:relative">
                                    <i class="icon-close" (click) = "deleteDoc(i)"><img src="../../../../assets/icons/close.svg" alt=""></i>

                                    <img src="../../../../assets/icons/doc.png">
                                    <p style="top:165px">{{url.Filename}}</p>
                                </div>
                                <div class="preview thumbnail" *ngIf=" url.FileType =='dwg' "  style="position:relative">
                                    <i class="icon-close" (click) = "deleteDoc(i)"><img src="../../../../assets/icons/close.svg" alt=""></i>

                                    <img src="../../../../assets/icons/dwg.png">
                                    <p style="top:165px">{{url.Filename}}</p>
                                </div>
                                <div class="preview thumbnail" *ngIf="  url.FileType =='zip' || url.FileType =='rar'"  style="position:relative">
                                    <i class="icon-close" (click) = "deleteDoc(i)"><img src="../../../../assets/icons/close.svg" alt=""></i>

                                    <img src="../../../../assets/icons/zip.png">
                                    <p style="top:165px">{{url.Filename}}</p>
                                </div>
                                <div class="preview thumbnail" *ngIf="  url.FileType =='xls'"  style="position:relative">
                                    <i class="icon-close" (click) = "deleteDoc(i)"><img src="../../../../assets/icons/close.svg" alt=""></i>

                                    <img src="../../../../assets/icons/xls.png">
                                    <p style="top:165px">{{url.Filename}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <span *ngIf="urls.length == 0" data-dismiss="modal" >SUBMIT</span>
                <span *ngIf="urls.length != 0" data-dismiss="modal" (click)=completeRevision()>SUBMIT</span>
            </div>
        </div>
    </div>
</div>

<div class="modal hide " data-keyboard="false" data-backdrop="static" tabindex="-1" role="dialog" id="exampleModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content loadingmodal">

            <div class="modal-body">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <span class="loadText">Loading please wait...</span>
            </div>

        </div>
    </div>
</div>