<main class="py-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <p class="title">About</p>
                <p class="link">Privacy Policy</p>
                <p class="link">Terms of Service</p>
            </div>
            <div class="col-lg-3">
                <p class="title">Support</p>
                <p class="link">Help & Support</p>
                <p class="link">Trust & Safety</p>
            </div>
            <div class="col-lg-3">
                <p style="font-weight: 700;">Illumine I</p>
                <p class="link"> <span>&copy;</span> Illumine Industries Ltd. 2020</p>
            </div>
            <div class="col-lg-3">
                <p class="title">Contact</p>
                <p class="link">illumineindustries@illumine.com</p>
            </div>
        </div>
    </div>
</main>