import { IconsModule } from './../../module/icon.module';
import { SharedmoduleModule } from './../../module/sharedmodule.module';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { BrowseprojectComponent } from './browseproject/browseproject.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ActiveprojectsComponent } from './activeprojects/activeprojects.component';
import { ActivebidsComponent } from './activebids/activebids.component';
import { CompleteprojectsComponent } from './completeprojects/completeprojects.component';
import { NgPipesModule, ReversePipe } from 'ngx-pipes';
import { ProjectdetailsComponent } from './projectdetails/projectdetails.component';
import { ActiveprojectsdetailComponent } from './activeprojectsdetail/activeprojectsdetail.component';
import { RevisionComponent } from './revision/revision.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { LoadingBarModule, LoadingBarService } from '@ngx-loading-bar/core';
import { RejectdetailComponent } from './rejectdetail/rejectdetail.component';

@NgModule({
  declarations: [DashboardComponent, BrowseprojectComponent, ActiveprojectsComponent, ActivebidsComponent, CompleteprojectsComponent, ProjectdetailsComponent, ActiveprojectsdetailComponent, RevisionComponent, RejectdetailComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    FormsModule,
    GooglePlaceModule,
    SharedmoduleModule,
    NgPipesModule,
    NgxPaginationModule,
    LoadingBarModule,
    IconsModule
  ], 
  providers: [ReversePipe, LoadingBarService]
})
export class DashboardModule { }
