<!-- <app-header></app-header> -->
<div class="main-container mb-5 pb-5">
    <ngx-loading-bar [includeSpinner] = false [color]="'#0066FF'"></ngx-loading-bar>

    <div class="container" *ngIf="loading == false; else elseBlock">
        <div class="row header-box">
            <div class="col-6 col-md-10">
                <p class="browse">Browse projects</p>
            </div>

            <div class="col-6 col-md-2 " *ngIf="totalDocs != undefined">
                <div class="mx-lg-auto" style=" display: flex; align-items: center;justify-content: center;">
                    <div class="filter" data-toggle="modal" data-target="#exampleModalCenter">
                        Filters <img src="../../../../assets/images/downarrow.svg" alt="">
                    </div>

                </div>
            </div>
        </div>
        <div class="container  p-0" style="margin-top:12px" *ngIf="resetBtn == true">
            <span class="resetBTN" (click)="Reset()">
                Reset Filter
            </span>
        </div>
        <ng-container *ngIf="Projects.length != 0; else templates">



            <div class="contant-box">

                
                <div class="card-container">
                    <div class="card" *ngFor="let project of Projects  | paginate: { itemsPerPage: 3, currentPage: p, totalItems:totalDocs } ; let i = index">
                        <div class="card-body">
                            <div class="row" (click)="navigate(project.project[0]?._id)">
                                <div class="col-xl-11">
                                    <h5> {{project.project[0]?.projectName | titlecase}}</h5>
                                    <p class="location">Project Location: <span> {{project.project[0]?.state }}</span></p>
                                </div>
                        
                            </div>
                            <div class="row" (click)="navigate(project.project[0]?._id)">
                                <div class="col-xl-12">
                                    <p class="description capital"> {{project.project[0]?.description}}</p>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 col-lg-10 d-flex flex-wrap"  (click)="navigate(project.project[0]?._id)"> 
                                    <div class="details mr-3 " >
                                        <div>
                                            <!-- <img src="../../../../assets/icons/education (1).svg" alt=""> -->
                                            <i-feather name="briefcase" class="big"></i-feather>
                                        </div>
                                        <div>
                                            <p class="title">License Type</p>
                                            <p class="value"> {{project.project[0]?.licenseType | titlecase}}</p>
                                        </div>
                                    </div>
                                    <div class="details mt-2 mt-lg-0" >
                                        <div>
                                            <!-- <img src="../../../../assets/icons/education (1).svg" alt=""> -->
                                            <i-feather name="calendar" class="big"></i-feather>
                                        </div>
                                        <div>
                                            <p class="title">Posted on</p>
                                            <p class="value"> {{project.project[0]?.createdAt | date:'short' : '-0800'}}</p>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="col-12 col-lg-2" >
                                    <div class="bids" (click)="sendId(i,project.project[0]?._id,project.project[0]?.projectName)" data-toggle="modal" data-target="#acceptmodel">
                                        Bid Now
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="my-5 d-flex justify-content-center " *ngIf="totalDocs > 5">

                        <pagination-controls (pageChange)=" pageChanged($event)"></pagination-controls>
                    </div>

                    
                </div>
            </div>


          
        </ng-container>
        <ng-template #templates>
            <div class="card verified">
                <div class="card-body p-lg-5">
                    <app-emptystates [message] = "emptyprojects"></app-emptystates>
                </div>
             </div>
        </ng-template>
    </div>
    <ng-template #elseBlock>
        <div style="display: flex;align-items: center;justify-content: center;height: 70vh;">
            <app-loading></app-loading>
        </div>
    </ng-template>

</div>

<div class="modal fade" id="acceptmodel" tabindex="-1" role="dialog" aria-labelledby="acceptmodelTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <p class="model-title" style="position: relative; bottom: 10px;"><b>{{Projectname | titlecase}}</b></p>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><img src="../../../../assets/icons/cross.svg" alt=""></span>
               </button>
            </div>
            <div class="modal-body">
                <div class="input-group mb-3 groups">
                    <div class="input-group-prepend ">
                        <span class="input-group-text" id="basic-addon3 " style="border: none;">Bid Value: $</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="bidammount" (keyup) = "plaformfee()"  id="basic-url" aria-describedby="basic-addon3" style="background:#EFF4F7;">
                </div>
                <!-- <p  style="    margin-left: 12px;">Platform fee: ${{platformFee}}</p>
                <p  style="font-weight: 700;    margin-left: 12px;">Total bid: ${{commission}}</p> -->

                <!-- <label for="" class="subtitle"> What's the bid split-up ?</label>
                <textarea class="form-control" aria-label="With textarea" [(ngModel)]="splitup" style="background:#EFF4F7"></textarea> -->

            </div>
            <div class="modal-footer ">

                <span class="btn-model apply" data-dismiss="modal" (click)="sendbid()">
                    Submit bid
                </span>

            </div>
        </div>
    </div>

</div>


  <!-- model filter -->
  <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog  modal-lg" role="document">
        <div class="modal-content" style="  height: 400px;">
            <div class="modal-header">
                <!-- <h5 class="modal-title" id="exampleModalLongTitle">Filter</h5> -->
                <p style="font-size: 19px;"><b>Filter Projects</b></p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true"><img src="../../../../assets/icons/cross.svg" alt=""></span>
</button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group ">
                            <select class="form-control " id="exampleFormControlSelect1 "  [(ngModel)]="location" style="border: 1px solid #ced4da; ">
                               <option disabled selected value> -- State -- </option>
                               <option  *ngFor="let state of states" [value]="state" >{{state}}</option>
                            </select>
                         </div>
                    </div>
                    <div class=" col-md-6 ">
                        <div class="form-group ">
                            <select class="form-control " id="exampleFormControlSelect1 " [(ngModel)]="licenseType" style="border: 1px solid #ced4da; ">
                                <option disabled selected value> -- License -- </option>
                                <option value="Agricultural and Biological Engineering">Agricultural and Biological Engineering</option>
                                <option  value="Architectural Engineering">Architectural Engineering</option>
                                <option  value="Chemical">Chemical</option>
                                <option  value="Civil">Civil</option>
                                <option  value="Control Systems">Control Systems</option>
                                <option  value="Electrical">Electrical</option>
                                <option  value="Environmental">Environmental</option>
                                <option  value="Fire Protection">Fire Protection</option>
                                <option  value="Industrial and Systems">Industrial and Systems</option>
                                <option  value="Mechanical">Mechanical</option>
                                <option  value="Metallurgical and Materials">Metallurgical and Materials</option>
                                <option  value="Mining and Mineral Processing">Mining and Mineral Processing</option>
                                <option  value="Naval Architecture and Marine">Naval Architecture and Marine</option>
                                <option  value="Nuclear">Nuclear</option>
                                <option  value="Petroleum">Petroleum</option>
                                <option  value="Structural">Structural</option>
                        </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer ">
                <span class="btn-model reset" (click)="resetValue()">
                     Reset
                 </span>
                <span class="btn-model apply" data-dismiss="modal" (click)="apply()">
                     Apply
                 </span>

            </div>
        </div>
    </div>
</div>