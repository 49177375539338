import { LoadingBarModule, LoadingBarService } from '@ngx-loading-bar/core';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { SharedmoduleModule } from './../../module/sharedmodule.module';
import { MustMatchDirective } from './../../directive/mustmatch/must-match.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OnboardRoutingModule } from './onboard-routing.module';
import { OnboardComponent } from './onboard.component';
import { SignupComponent } from './signup/signup.component';
import { VerifyemailComponent } from './verifyemail/verifyemail.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { ViewprofileComponent } from './viewprofile/viewprofile.component';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { SuccessmailComponent } from './successmail/successmail.component';


@NgModule({
  declarations: [MustMatchDirective,  OnboardComponent, SignupComponent, VerifyemailComponent, RegisterComponent, LoginComponent, ViewprofileComponent, ForgetpasswordComponent, SuccessmailComponent],
  imports: [
    CommonModule,
    OnboardRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedmoduleModule,
    GooglePlaceModule,
    LoadingBarModule
  ], 
  providers: [ LoadingBarService ]
})
export class OnboardModule { }
